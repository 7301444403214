/* eslint-disable no-unused-vars */
import moment from 'moment'
import {
  compensationHistoryOpen,
  compensationHistoryClose,
} from '../../../../navigation/navigationSlice'

const paginateArray = (array, pageNumber) => {
  const itemsPerPage = 10
  const startIndex = (pageNumber - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  return array.slice(startIndex, endIndex)
}

const handlePageChange = (page, setCurrentPage) => {
  setCurrentPage(page)
}

const exitPage = (history) => {
  history.push('/dashboard')
}

const setOpen = (openDialog, dispatch) => {
  if (openDialog) {
    dispatch(compensationHistoryClose())
  } else {
    dispatch(compensationHistoryOpen())
  }
}

const resetDates = (
  setStartDate,
  setEndDate,
  originalStart,
  openDialog,
  dispatch,
) => {
  setStartDate(originalStart)
  setEndDate(moment().format('YYYY-MM-DD'))
  setOpen(openDialog, dispatch)
}

const handleClose = (setTempStart, setTempEnd, openDialog, dispatch) => {
  setTempStart('')
  setTempEnd('')
  setOpen(openDialog, dispatch)
}
const handleSave = (
  tempStart,
  tempEnd,
  setStartDate,
  setTempStart,
  setEndDate,
  setTempEnd,
  openDialog,
  dispatch,
) => {
  if (tempStart) {
    setStartDate(tempStart)
    setTempStart('')
  }
  if (tempEnd) {
    setEndDate(tempEnd)
    setTempEnd('')
  }
  setOpen(openDialog, dispatch)
}

const handleSalaryClick = (date, history) => {
  const noSlashDate = date.replaceAll('/', '')
  history.push(`/CompensationHistory/${noSlashDate}`)
}

const handleStartChange = (e, setTempStart) => {
  setTempStart(e.target.value)
}
const handleEndChange = (e, setTempEnd) => {
  setTempEnd(e.target.value)
}

// TODO: check error in console, it doesn't like date format
const filterRows = (itemDoc, start, end) => {
  if (moment(itemDoc).isBetween(start, end, 'day', [])) {
    return true
  }
  return false
}

// single compensation helpers
const extractAfterLastSlash = (str) => {
  if (str === null || str === undefined) {
    return ''
  }
  const lastSlashIndex = str.lastIndexOf('/')
  if (lastSlashIndex === -1) {
    return str
  }
  return str.substring(lastSlashIndex + 1)
}

const calculateDifference = (n1, n2) => {
  if (n1 !== null && n2 !== null) {
    return n1 - n2
  }
  return null
}

export {
  paginateArray,
  handlePageChange,
  exitPage,
  setOpen,
  resetDates,
  handleClose,
  handleSave,
  handleSalaryClick,
  handleStartChange,
  handleEndChange,
  filterRows,
  extractAfterLastSlash,
  calculateDifference,
}
