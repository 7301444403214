import React from 'react'
import {
  Typography,
  IconButton,
  Grid,
  Divider,
  Button,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { useTheme } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import Pagination from '@material-ui/lab/Pagination'
import PaginationItem from '@material-ui/lab/PaginationItem'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Modal from '../../../../CommonComponents/Modal/Modal'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'
import { selectCompensationHistoryIsOpen } from '../../../../navigation/navigationSlice'
import {
  EmployeeInfo,
  TableHeaderCell,
  CompHistoryRow,
  DateFilterContent,
  blankUser,
} from './CompensationHistoryComponents'
import {
  paginateArray,
  handlePageChange,
  exitPage,
  setOpen,
  resetDates,
  handleClose,
  handleSave,
  handleSalaryClick,
  handleStartChange,
  handleEndChange,
  filterRows,
} from './CompensationHistoryHelpers'
import {
  fetchCompensationHistory,
  selectCHUser,
  selectCHData,
  selectCHStatus,
} from './CompensationHistorySlice'
import { getImageFromFirebaseWithHook } from '../../../apps/helpers'
import { CompensationHistoryStyles } from './CompensationHistoryStyles'

function CompensationHistory() {
  const dispatch = useDispatch()
  const classes = CompensationHistoryStyles()
  const theme = useTheme()
  const history = useHistory()
  const isXSmall = useMediaQuery(() => theme.breakpoints.down('xs'))
  const isSmall = useMediaQuery(() => theme.breakpoints.down('sm'))
  const openDialog = useSelector(selectCompensationHistoryIsOpen)
  const [startDate, setStartDate] = React.useState('1900-01-01')
  const [tempStart, setTempStart] = React.useState('')
  const [originalStart, setOriginalStart] = React.useState('')
  const [endDate, setEndDate] = React.useState(moment().format('YYYY-MM-DD'))
  const [tempEnd, setTempEnd] = React.useState('')
  const [currentPage, setCurrentPage] = React.useState(1)
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [image, setImage] = React.useState(
    `${process.env.REACT_APP_IMAGES_URL}/assets/images/profile_default_avatar.png`,
  )

  const user = useSelector(selectCHUser)
  const chData = useSelector(selectCHData)
  const chStatus = useSelector(selectCHStatus)

  React.useEffect(() => {
    dispatch(fetchCompensationHistory())
  }, [])
  React.useEffect(() => {
    if (user) {
      getImageFromFirebaseWithHook(user.profilePhoto, setImage)
    }
  }, [user])
  React.useEffect(() => {
    if (chStatus === 'fulfilled') {
      setStartDate(
        moment(chData[chData.length - 1].dateOfChange).format('YYYY-MM-DD'),
      )
      setOriginalStart(
        moment(chData[chData.length - 1].dateOfChange).format('YYYY-MM-DD'),
      )
      setTimeout(() => setIsLoaded(true), '200')
    }
  }, [chStatus])

  const pages = Math.ceil(
    chData?.filter((item) => filterRows(item.dateOfChange, startDate, endDate))
      .length / 10,
  )

  return (
    <div className={classes.container}>
      <div className={classes.navbar}>
        <Button
          className={classes.backButton}
          onClick={() => exitPage(history)}
          aria-label="Back to Dashboard"
        >
          <ArrowBackIcon className={classes.backArrow} />
          <Typography variant="h2">Compensation History</Typography>
        </Button>
      </div>
      <Grid container className={classes.body}>
        <Grid item container className={classes.box}>
          <Grid item className={classes.headerBar}>
            <Typography variant="h3">
              <b>Employee Job Information</b>
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            className={classes.paper}
          >
            <Grid item>
              <img
                className={classes.profilePicture}
                src={image}
                alt={`${user?.firstName} ${user?.lastName}`}
              />
            </Grid>
            <Grid item>
              {isLoaded ? (
                <Typography variant="h4">
                  <b>{`${user?.firstName} ${user?.lastName}`}</b>
                </Typography>
              ) : (
                <Grid container spacing={1}>
                  <Grid item>
                    <Skeleton width={80} height={21} />
                  </Grid>
                  <Grid item>
                    <Skeleton width={100} height={21} />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Divider className={classes.divider} />
            <Grid
              item
              container
              alignItems="center"
              style={{ marginBottom: isLoaded ? '0px' : '2.5px' }}
            >
              {EmployeeInfo({ user: isLoaded ? user : blankUser })}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container className={classes.box}>
          <Grid item className={classes.headerBar}>
            <Typography variant="h3">
              <b>Compensation History</b>
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            className={classes.paper}
          >
            <Grid item xs={6} className={classes.keyGrid}>
              <Typography variant="subtitle1" className={classes.key}>
                Date Range
              </Typography>
            </Grid>
            <Grid item container direction="row" alignItems="center" xs={6}>
              <Grid item>
                <Tooltip
                  title={
                    <Typography variant="subtitle2">Date Filter</Typography>
                  }
                  placement="bottom"
                  arrow
                >
                  <IconButton
                    aria-label="Date Filter"
                    className={classes.filterIcon}
                    onClick={() => setOpen(openDialog, dispatch)}
                  >
                    <IconPicker iconName="fa-solid fa-filter" color="orange" />
                  </IconButton>
                </Tooltip>
                <Modal
                  open={openDialog}
                  dialogTitle="Date Filter"
                  onCloseDialog={() =>
                    handleClose(setTempStart, setTempEnd, openDialog, dispatch)
                  }
                  dialogContent={
                    <DateFilterContent
                      start={startDate}
                      end={endDate}
                      tempStart={tempStart}
                      tempEnd={tempEnd}
                      handleStartChange={(e) =>
                        handleStartChange(e, setTempStart)
                      }
                      handleEndChange={(e) => handleEndChange(e, setTempEnd)}
                      isSmall={isSmall}
                    />
                  }
                  dialogActions={
                    <>
                      <Button
                        onClick={() =>
                          resetDates(
                            setStartDate,
                            setEndDate,
                            originalStart,
                            openDialog,
                            dispatch,
                          )
                        }
                        disabled={
                          startDate === originalStart &&
                          endDate === moment().format('YYYY-MM-DD')
                        }
                        variant="outlined"
                        color="primary"
                      >
                        Reset Dates
                      </Button>
                      <Button
                        onClick={() =>
                          handleSave(
                            tempStart,
                            tempEnd,
                            setStartDate,
                            setTempStart,
                            setEndDate,
                            setTempEnd,
                            openDialog,
                            dispatch,
                          )
                        }
                        disabled={tempStart === '' && tempEnd === ''}
                        variant="contained"
                        color="secondary"
                      >
                        Save
                      </Button>
                    </>
                  }
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.bodyText}>
                  {moment(startDate).format('MM/DD/YYYY')} -{isXSmall && <br />}{' '}
                  {moment(endDate).format('MM/DD/YYYY')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Divider className={classes.divider} />
              <Typography variant="body1" className={classes.bodyText}>
                Select a row to see more details
              </Typography>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item container>
              <Grid item container alignItems="center" className={classes.row}>
                <TableHeaderCell string1="Date of" string2="Change" />
                <TableHeaderCell string1="Change" />
                <TableHeaderCell string1="Annual Salary" />
                <TableHeaderCell
                  isLastBox
                  string1="Compensation"
                  string2="per Frequency"
                />
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
              {isLoaded &&
                paginateArray(
                  chData.filter((item) =>
                    filterRows(item.dateOfChange, startDate, endDate),
                  ),
                  currentPage,
                ).map((item, i) => (
                  <CompHistoryRow
                    key={`${item.dateOfChange}${Math.random()}`}
                    item={item}
                    index={i}
                    handleSalaryClick={() =>
                      handleSalaryClick(item.dateOfChange, history)
                    }
                  />
                ))}
              {pages > 1 && (
                <Grid item container justifyContent="flex-end">
                  <Grid item className={classes.paginationGrid}>
                    <Pagination
                      count={pages}
                      page={currentPage}
                      variant="outlined"
                      color="secondary"
                      onChange={(e, page) =>
                        handlePageChange(page, setCurrentPage)
                      }
                      renderItem={(item) => (
                        <PaginationItem
                          {...item}
                          className={classes.pageCircle}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default CompensationHistory
