import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Grid } from '@material-ui/core'
import WhatsNewSearchBar from '../searchBar/WhatsNewSearchBar'
import WhatsItemListStyles from './whatsNewItemListStyles'
import WhatsNewCard from '../itemCard/WhatsNewItemCard'

import {
  selectWhatsNewStatus,
  selectWhatsNewData,
  fetchWhatsNewArticles,
} from '../../WhatsNewPageSlice'

// develop responsive width and sticky position for Searchbar
// Fix justofy content for Searchbar and itemlist container
export default function WhatsNewItemList() {
  const callArticlesState = useSelector(selectWhatsNewStatus)
  const whatsNewData = useSelector(selectWhatsNewData)
  const [searchTerm, setSearchTerm] = React.useState('')
  const [categories, setCategories] = React.useState([])
  const [types, setTypes] = React.useState([])
  const [items, setItems] = React.useState([])
  const [categoryFilterValues, setCategoryFilterValues] = React.useState([])
  const [typeFilterValues, setTypeFilterValues] = React.useState([])
  const classes = WhatsItemListStyles()
  const itemcategories = (articles) => articles.map((item) => item.category)
  const itemtypes = (articles) => articles.map((item) => item.type)
  const updateItems = (newList, setStateHook) => {
    setStateHook((prevItems) => {
      const updatedItems = [...prevItems]
      // Add new items that don't exist
      newList.forEach((newItem) => {
        if (!updatedItems.includes(newItem)) {
          updatedItems.push(newItem)
        }
      })

      // Remove items that are not in the new list
      const filteredItems = updatedItems.filter((item) =>
        newList.includes(item),
      )

      return filteredItems
    })
  }

  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(fetchWhatsNewArticles())
  }, [])

  React.useEffect(() => {
    if (callArticlesState === 'fulfilled') {
      updateItems(itemcategories(whatsNewData.articles.articles), setCategories)
      updateItems(itemtypes(whatsNewData.articles.articles), setTypes)
      handleFiltering()
    }
  }, [callArticlesState])

  React.useEffect(() => {
    handleFiltering()
  }, [categoryFilterValues, typeFilterValues, searchTerm])
  // call handleFiltering to process text search
  const handleRequestSearch = () => handleFiltering()
  // set searchterm values for user input
  function handleInputChange(e) {
    setSearchTerm(e.target.value)
  }
  const onCancelSearch = () => {
    setSearchTerm('')
  }
  // function to process text searhc and handle filtering requests
  function handleFiltering() {
    // initate query results (text search + filtering outputs)
    const queryResult = []
    const combinedFilterKeys = [...categoryFilterValues, ...typeFilterValues]
    const filterParams = {
      category: categoryFilterValues,
      type: typeFilterValues,
    }
    // initial query object (only includes search-term if user input is > 3 chars.)
    const query = {
      title: searchTerm.length > 3 ? searchTerm : null,
      filterValues: combinedFilterKeys.length === 0 ? null : filterParams,
    }

    // validating each query (text search, type filter, category filter)
    const validQueries = Object.values(query)
      .map((value, index) => (value ? index : -1))
      .filter((index) => index !== -1)

    function filterQueryBuilder(item) {
      const queryObject = query.filterValues

      // check if filter includes category value
      // function categoryValueMapping(categoryQuery) {
      //   let hasCategory = false
      //   if ((categoryQuery === 'categories' && item.category !== null) || '') {
      //     hasCategory = true
      //   }
      //   console.log(
      //     'query filterQueryBuilder called: hAS CATEGORY ',
      //     categoryQuery,
      //     hasCategory,
      //   )
      //   return false
      // }

      // // TODO : review type and category value mapping,
      // // they always must return false for the filters to work correctly check if filter includes type values
      // function typeValueMapping(filterQuery) {
      //   let hasType = false
      //   if ((filterQuery === 'type' && item.type !== null) || '') {
      //     hasType = true
      //   }
      //   console.log('query filterQueryBuilder called: hAS TYPE ', hasType)
      //   return false
      // }
      // index shows how many active queries are present and the indexes of valid queries
      const index =
        queryObject &&
        Object.values(queryObject)
          .map((value, idx) => (Boolean(value) && value.length ? idx : -1))
          .filter((idx) => idx !== -1)

      /* baseQuery is when only one filter is active
         must handle all filters separately with respect to data type of
         event values 1. theme: (single string) 
         2. benefits:(array of strings) 3.categories(array of objects) */
      function baseQuery(idx) {
        return (
          // categoryValueMapping(Object.keys(queryObject)[idx]) ||
          // typeValueMapping(Object.keys(queryObject)[idx]) ||
          Object.values(queryObject)[idx].includes(
            item[Object.keys(queryObject)[idx]],
          )
        )
      }

      if (index.length === 1) {
        return baseQuery(index)
      }

      return baseQuery(index[0]) && baseQuery(index[1])
    }

    // handle array cases with applyQueries
    function applyQueries(item, itemKey, queryValue) {
      const arrayCheck = Array.isArray(query[itemKey])
      const stringCheck = typeof query[itemKey] === 'string'
      const objectCheck = typeof query[itemKey] === 'object'
      let appliedQuery = false

      if (
        !arrayCheck &&
        stringCheck &&
        item[itemKey]?.toLowerCase().includes(queryValue.toLowerCase())
      ) {
        appliedQuery = true
      }
      if (
        !arrayCheck &&
        !stringCheck &&
        objectCheck &&
        filterQueryBuilder(item)
      ) {
        appliedQuery = true
      }
      return appliedQuery
    }

    function builder(item) {
      if (validQueries.length === 1) {
        return applyQueries(
          item,
          Object.keys(query)[validQueries[0]],
          query[Object.keys(query)[0]],
        )
      }
      if (validQueries.length === 2) {
        return (
          applyQueries(
            item,
            Object.keys(query)[0],
            query[Object.keys(query)[0]],
          ) &&
          applyQueries(
            item,
            Object.keys(query)[1],
            query[Object.keys(query)[1]],
          )
        )
      }
      return []
    }

    if (validQueries.length) {
      whatsNewData?.articles.articles.filter(
        (item) => builder(item) && queryResult.push(item),
      )
    }

    // remove duplicates from final output
    const output = queryResult.filter(
      (obj, index, self) =>
        index === self.findIndex((t) => t.title === obj.title),
    )
    // if no valid queries return all items
    if (validQueries.length) {
      setItems(output)
    } else {
      setItems(whatsNewData.articles.articles)
    }
  }

  function handleCategoryFilteringRequest(filterValues) {
    setCategoryFilterValues(filterValues)
  }

  function handleTypeFilteringRequest(filterValues) {
    setTypeFilterValues(filterValues)
  }
  return (
    <Box className={classes.container}>
      <WhatsNewSearchBar
        onRequestSearch={handleRequestSearch}
        onCancelSearch={onCancelSearch}
        onChange={handleInputChange}
        categories={categories}
        types={types}
        onFilteringCategory={handleCategoryFilteringRequest}
        onFilteringType={handleTypeFilteringRequest}
        value={searchTerm}
      />
      <Box>
        {callArticlesState === 'fulfilled' ? (
          <Grid container className={classes.itemList} spacing={2}>
            {items?.map((item, idx) => (
              <Grid item key={item.title}>
                <WhatsNewCard item={item} idx={idx} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box>Loading</Box>
        )}
      </Box>
    </Box>
  )
}
