import * as React from 'react'
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Button,
  IconButton,
  Tooltip,
  Box,
  Paper,
  TextField,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { useDispatch } from 'react-redux'
import Modal from '../../../../CommonComponents/Modal/Modal'
import { searchClose, searchOpen } from '../../../../navigation/navigationSlice'
import SearchDialogStyles from './SearchDialogStyles'

const SearchDialog = ({ open, isMobile }) => {
  const tabs = ['People', 'Websites', 'Rutgers Health', 'Buildings']
  const dispatch = useDispatch()
  const classes = SearchDialogStyles()
  const [search, setSearch] = React.useState('')
  const handleTextInput = (event) => {
    setSearch(event.target.value)
  }
  const [tabValue, setTabValue] = React.useState(0)
  const handleChange = (event) => {
    const currentTabValue = event.target.value
    setSearch('')
    setTabValue(currentTabValue)
  }

  const searchTypes = {
    rutgers: {
      options: {
        People: {
          title: 'People',
          placeholder: 'E.g. Smith, Jo*n',
          hintText: 'Search for name or directory listing name',
          url: 'http://search.rutgers.edu/people.html?q=',
          examples: <HintsTable classes={classes} />,
          imageURL: `${process.env.REACT_APP_IMAGES_URL}/assets/images/People-square.png`,
        },
        Websites: {
          title: 'Websites',
          placeholder: 'E.g. rutgers.edu',
          hintText:
            'Search for Rutgers specific website URL based your search term',
          url: 'http://search.rutgers.edu/web.html?q=',
          examples: (
            <Typography align="left" sx={{ whitespace: 'pre-line' }}>
              site:admissions.rutgers.edu applying to Rutgers
            </Typography>
          ),
          imageURL: `${process.env.REACT_APP_IMAGES_URL}/assets/images/Website-square.png`,
        },
        'Rutgers Health': {
          title: 'Rutgers Health',
          placeholder: 'E.g. Smith, Jo*n',
          hintText:
            'Search for an RBHS specific faculty and staff contact information',
          url: 'https://search.rutgers.edu/rutgershealth?q=',
          examples: (
            <Typography align="left" sx={{ whitespace: 'pre-line' }}>
              Use people search to find Rutgers Health faculty and staff contact
              information.
            </Typography>
          ),
          imageURL: `${process.env.REACT_APP_IMAGES_URL}/assets/images/RutgersHealth-square.png`,
        },
        Buildings: {
          title: 'Buildings',
          placeholder: 'E.g. Old Queens',
          hintText:
            'Search for Rutgers specific buildings such as Student Services Center, ASB I, ASB II',
          url: 'http://search.rutgers.edu/buildings.html?q=',
          examples: (
            <Typography align="left" sx={{ whitespace: 'pre-line' }}>
              Examples: Old Queens or RAC or Student Services Center
            </Typography>
          ),
          imageURL: `${process.env.REACT_APP_IMAGES_URL}/assets/images/Building-square.png`,
        },
      },
    },
  }
  const performSearch = () => {
    const url = `${
      searchTypes.rutgers.options[tabs[tabValue]].url
    }${encodeURIComponent(search)}`
    window.open(url)
  }

  const handleClose = () => {
    setSearch('')
    dispatch(searchClose())
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      performSearch()
    }
  }

  const onclicktest = () => {
    dispatch(searchOpen())
  }

  return (
    <div className={classes.flex}>
      <Tooltip
        title={<Typography variant="subtitle2">Rutgers Search</Typography>}
        placement="top"
        arrow
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="rutgers search menu"
          onClick={onclicktest}
        >
          <SearchIcon className={classes.appBarIcon} />
        </IconButton>
      </Tooltip>

      <SearchDialogModal
        open={open}
        handleClose={handleClose}
        tabValue={tabValue}
        handleChange={handleChange}
        search={search}
        handleTextInput={handleTextInput}
        handleKeyPress={handleKeyPress}
        performSearch={performSearch}
        tabs={tabs}
        classes={classes}
        searchTypes={searchTypes}
        isMobile={isMobile}
      />
    </div>
  )
}

export default SearchDialog

function SearchDialogModal({
  open,
  handleClose,
  tabValue,
  handleChange,
  search,
  handleTextInput,
  handleKeyPress,
  performSearch,
  tabs,
  classes,
  searchTypes,
  isMobile,
}) {
  return (
    <Modal
      open={open}
      onCloseDialog={handleClose}
      dialogTitle="Rutgers Search"
      dialogContent={
        <Grid
          container
          justifyContent="space-evenly"
          className={classes.modalContainer}
        >
          <Grid item className={classes.widgetContainer}>
            <Paper id="report-grid-item" className={classes.paper}>
              <Grid container direction="column" spacing={2}>
                <Grid item container alignItems="center" xs={12}>
                  <Grid item xs={3}>
                    <Typography style={{ padding: 10 }}>Search Type</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Select
                      labelId="rutgers-search-select"
                      value={tabValue}
                      onChange={handleChange}
                      className={classes.select}
                      variant="outlined"
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'right',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'right',
                        },
                      }}
                    >
                      {tabs.map((tab, index) => (
                        <MenuItem key={tab} value={index}>
                          <Typography align="left">{tab}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
                <Grid item container alignItems="center" xs={12}>
                  <Grid item xs={3}>
                    <Typography style={{ padding: 10 }}>Search Term</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      placeholder={
                        searchTypes.rutgers.options[tabs[tabValue]].placeholder
                      }
                      variant="outlined"
                      onChange={handleTextInput}
                      value={search}
                      onKeyDown={handleKeyPress}
                      className={classes.input}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item className={classes.widgetContainer}>
            <Paper id="report-grid-item" className={classes.paper}>
              <Grid
                direction={isMobile ? 'column' : 'row'}
                className={classes.hintsGrid}
              >
                <Grid item container alignItems="center">
                  <Box className={classes.imageContainer}>
                    <img
                      alt="Search Img"
                      src={searchTypes.rutgers.options[tabs[tabValue]].imageURL}
                      style={{
                        objectFit: 'cover',
                        width: isMobile ? '50%' : '100%',
                        height: isMobile ? '50%' : '100%',
                        borderRadius: '5%',
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item container alignItems="center">
                  <Box
                    sx={{
                      marginLeft: 30,
                      width: isMobile ? '85%' : '100%',
                      textAlign: 'left',
                      marginTop: isMobile ? '10%' : 0,
                    }}
                  >
                    <Typography align="left">Hint</Typography>

                    <Typography align="left">
                      {searchTypes.rutgers.options[tabs[tabValue]].hintText}
                    </Typography>
                    <Box sx={{ marginTop: 15 }}>
                      {searchTypes.rutgers.options[tabs[tabValue]].examples}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      }
      dialogActions={
        <Button
          onClick={performSearch}
          disabled={search.length === 0}
          color="primary"
          variant="contained"
          className={classes.searchButton}
        >
          RU Search
        </Button>
      }
    />
  )
}

function HintsTable({ classes }) {
  console.log('c', classes)
  return (
    <table style={{ width: '100%' }}>
      <tbody>
        <tr>
          <td>Smith, Jo*n:</td>
          <td>Full name search with wildcard (*)</td>
        </tr>
        <tr>
          <td colSpan="2">
            <hr className={classes.line} />
          </td>
        </tr>
        <tr>
          <td>John Smith:</td>
          <td>Full name search</td>
        </tr>
        <tr>
          <td colSpan="2">
            <hr className={classes.line} />
          </td>
        </tr>
        <tr>
          <td>Smith:</td>
          <td>Last name search</td>
        </tr>
        <tr>
          <td colSpan="2">
            <hr className={classes.line} />
          </td>
        </tr>
        <tr>
          <td>,John:</td>
          <td>First name search</td>
        </tr>
        <tr>
          <td colSpan="2">
            <hr className={classes.line} />
          </td>
        </tr>
      </tbody>
    </table>
  )
}
