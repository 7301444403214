import * as React from 'react'
import { Grid } from '@material-ui/core'

import DashboardLocalButtons from '../sections/DashboardLocalButtons'
import MyAppsLocalButtons from '../sections/MyAppsLocalButtons'
import NewsLocalButtons from '../sections/NewsLocalButtons'
import MapsLocalButtons from '../sections/MapsLocalButtons'
import SettingsLocalButtons from '../sections/SettingsLocalButtons'

// TODO Handle other pages unique cases for additional side buttons

// Renders the button for the top app bar based on the component
export default function renderAppBarLocalButtons(
  location,
  classes,
  dispatch,
  history,
  isMobile,
  showSensitiveInfo,
  dialogOpen,
  searchOpen,
  activeAlerts,
  search,
  userProfile,
) {
  const appBarLocalButtons = new Map([
    ['dashboard', ['/']],
    ['apps', ['/apps', '/ViewAppInfo', '/infoPage', '/ViewAppInfoPage']],
    ['news', ['/news', '/ViewArticlePage', '/NewsSourcePage']],
    ['maps', ['/maps']],
    ['settings', ['/settings']],
  ])

  const buttonRenderers = {
    dashboard: () =>
      DashboardLocalButtons(
        classes,
        history,
        dispatch,
        showSensitiveInfo,
        isMobile,
        dialogOpen,
        searchOpen,
        activeAlerts,
        userProfile,
      ),
    apps: () =>
      MyAppsLocalButtons(classes, isMobile, history, dispatch, userProfile),
    news: () =>
      NewsLocalButtons(
        classes,
        history,
        isMobile,
        dispatch,
        search,
        userProfile,
      ),
    maps: () => (
      <MapsLocalButtons
        classes={classes}
        isMobile={isMobile}
        history={history}
        dispatch={dispatch}
        userProfile={userProfile}
      />
    ),
    settings: () => <SettingsLocalButtons history={history} />,
  }

  // Find which key the location matches
  const matchedSection = [...appBarLocalButtons.keys()].find((section) =>
    appBarLocalButtons.get(section).includes(location),
  )

  if (matchedSection) {
    return buttonRenderers[matchedSection]()
  }
  return <Grid item md={4} />
}
