import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  ringContainer: {
    width: 100,
    height: 100,
    borderRadius: '1rem',
  },
  visibilityOff: {
    paddingTop: 7,
    fontSize: 50,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  labelText: {
    color: theme.palette.base.black,
    width: '90px',
    borderBottomLeftRadius: '1rem',
    borderBottomRightRadius: '1rem',
    lineHeight: 'normal',
  },
  usedLabelText: {
    color: theme.palette.primary.white,
    width: '90px',
    lineHeight: '1.2',
    fontSize: 14,
    borderBottomLeftRadius: '1rem',
    borderBottomRightRadius: '1rem',
  },
  dataContainer: {
    marginTop: '5px',
    height: '50px',
  },
  valueHover: {
    fontSize: '20px',
    fontWeight: 600,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

export default styles
