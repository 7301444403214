import { makeStyles } from '@material-ui/core/styles'

export const filterStyles = makeStyles(() => ({
  root: {
    padding: (props) => (props.isMobile ? '0px 4px' : '10px 4px'),
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px !important',
    width: '99%',
  },
  input: {
    // marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    color: 'gray',
  },
  searchButton: {
    textTransform: 'none !important',
    background: 'rgb(152, 19, 50) !important',
    borderRadius: '6px !important',
    border: '1px solid rgb(141, 40, 38) !important',
    padding: '8px 20px !important',
    marginRight: '8px !important',
    '&:hover': {
      background: 'rgb(139, 0, 20) !important',
    },
  },
  buttonText: {
    color: 'white',
    fontSize: '14px !important',
    fontStyle: 'normal !important',
  },

  mobileSearchButton: {
    width: '100%',
    marginTop: '20px',
  },
  eventFilterBarContainer: {
    marginTop: '20px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px !important',
    width: '100%',
  },
  eventFilterBarLabel: {
    marginLeft: '10px',
    marginRight: '10px',
    background: '#e8eaed',

    color: 'black',
    padding: '9px',
    position: 'absolute',
    top: 0,
    left: -10,
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
  },

  eventFiltersContainer: {
    marginLeft: '20%',
    display: 'flex',
    flexDirection: 'row',
  },
  filtersWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContents: 'space-between',
  },
  mobileFiltersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignContent: 'space-between',
    height: '200px',
    minWidth: '290px',
  },
  mobileEventFilterBarContainer: {
    marginTop: '30px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px !important',
    width: '100%',
  },
  mobileEventFiltersContainer: {
    marginLeft: '40%',
    display: 'flex',
    margin: '5px',
    flexDirection: 'row',
  },
  filterWrapper: {
    display: 'flex',
    justifyContents: 'space-between',
    alignItems: 'center',
    marginRight: '3%',
  },

  categoryFilterWrapper: {
    border: 'none',
  },
  typeFilterWrapper: {
    border: 'none',
    marginRight: '5px',
  },
}))
