import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Widget from '../Widget'
import {
  fetchMyBenefitsTwo,
  selectMyBenefitsTwoData,
  selectMyBenefitsTwoStatus,
} from './MyBenefitsTwoSlice'
import Loading from '../helpers/Loading'
import styles from './MyBenefitsTwoStyles'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'
import HiddenEyeButtonTwo from '../../DashboardComponents/HiddenEyeButtonTwo/HiddenEyeButtonTwo'
import LinkList from '../../DashboardComponents/LinkList.js/LinkList'

// eslint-disable-next-line no-unused-vars
function MyBenefitsTwo({
  className,
  title = 'My Benefits',
  widgetId = '441',
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
  employeeId = '10000120',
}) {
  const classes = styles()
  const dispatch = useDispatch()
  const widgetData = useSelector(selectMyBenefitsTwoData)
  const widgetStatus = useSelector(selectMyBenefitsTwoStatus)

  React.useEffect(() => {
    dispatch(fetchMyBenefitsTwo({ personNumber: '10000120' }))
  }, [])

  React.useEffect(() => {
    if (employeeId) {
      dispatch(fetchMyBenefitsTwo({ personNumber: employeeId }))
    }
  }, [employeeId])

  return (
    <Widget className={className}>
      <Widget.Header title={title} />
      <Widget.Content
        className={classes.container}
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderMyBenefitsState(widgetStatus, widgetData, widgetId, classes)}
      </Widget.Content>
    </Widget>
  )
}

function renderMyBenefitsState(widgetStatus, widgetData, widgetId, classes) {
  console.log('widgetData ', widgetData)
  switch (widgetStatus) {
    case 'fulfilled':
      return (
        <Loaded widgetData={widgetData} widgetId={widgetId} classes={classes} />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return <Box></Box>
  }
}

function Loaded({ widgetData, widgetId, classes }) {
  const dispatch = useDispatch()

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.mainGrid}
      wrap="nowrap"
    >
      <Grid item className={classes.header}>
        <Typography variant="body1">
          <b>{widgetData?.pto?.subtitle}</b>
        </Typography>
      </Grid>
      <Grid item className={classes.absence}>
        <AbsenceGrid
          classes={classes}
          absenceList={widgetData?.pto?.hiddenEyeButtons}
          widgetData={widgetData}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      </Grid>
      <Grid className={classes.links}>
        <LinkList
          links={widgetData?.pto?.iconLinks}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      </Grid>
    </Grid>
  )
}

function AbsenceGrid({ classes, absenceList, dispatch, widgetId }) {
  return (
    <Grid
      container
      direction="column"
      alignContent="center"
      justifyContent="space-around"
      spacing={2}
      className={classes.dataRingsContainer}
    >
      <Grid item container alignItems="flex-start" spacing={2}>
        {absenceList.map((category, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid key={index} item xs={4} className={classes.whiteIcon}>
            <HiddenEyeButtonTwo
              widgetId={widgetId}
              dispatch={dispatch}
              label={category.iconLabel}
              value={category?.dayleft ?? 'N/A'}
              showUsedValue={`${category?.daytaken}` ?? 'N/A used'}
              backgroundColor={category.color}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default MyBenefitsTwo
