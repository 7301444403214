import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  Button,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Chip,
  Divider,
  Typography as Text,
} from '@material-ui/core'
import ThumbUpOnAltIcon from '@material-ui/icons/ThumbUpAlt'
import ThumbUpOffAltIcon from '@material-ui/icons/ThumbUpAltOutlined'
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite'
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode'
import clsx from 'clsx'
import {
  updateArticleLikedByUser,
  selectWhatsNewLikedArticles,
} from '../../WhatsNewPageSlice'

import VideoPlayer from './VideoPlayer'
import styles from './WhatsNewItemCardStyles'
import { getImageFromFirebaseWithHook } from '../../../../helpers'

const WhatsNewCard = ({ item, idx }) => {
  // const whatsNewData = useSelector(selectWhatsNewLikedArticles)
  const likedArticles = useSelector(selectWhatsNewLikedArticles)
  console.log('LikedArticles: ', likedArticles)
  const dispatch = useDispatch()
  const [image, setImage] = React.useState('')
  const [liked, setliked] = React.useState(false) // update this to state then for server data
  const [numOflikes, setnumOflikes] = React.useState(0) // update this to state then for server data
  const [showVideoPrev, setShowVideoPrev] = React.useState(false)
  const [mouseOverPreview, setMouseOverPreview] = React.useState(false)
  const [enableMouseOverPreview, setEnableMouseOverPreview] =
    React.useState(false)
  // CONTINUE from here, optimize and clean up like check with null check and see why it jumps to 137, remmber it was hard coeded 136
  React.useEffect(() => {
    setnumOflikes(item?.likedList.length)
    if (likedArticles.includes(item.id)) {
      setliked(true)
    }
  }, [likedArticles])
  React.useEffect(() => {
    getImageFromFirebaseWithHook(item.image, setImage)
  }, [])

  // function to enable/disable onMouse ober show video preview
  // required to prevent showing preview while the video is not loaded or ready to be played
  React.useEffect(() => {
    if (showVideoPrev) {
      setEnableMouseOverPreview(true)
    }
  }, [showVideoPrev])

  const classes = styles({ showVideoPrev })
  const history = useHistory()

  function handleLikeAction() {
    if (!liked) {
      dispatch(updateArticleLikedByUser({ articleID: item.id, add: true }))
      setliked(!liked)
      // setnumOflikes((prevNum) => prevNum + 1)
      console.log('liked')
    } else if (liked) {
      console.log('disliked')
      dispatch(updateArticleLikedByUser({ articleID: item.id, add: false }))
      // setnumOflikes((prevNum) => prevNum - 1)
      setliked(!liked)
    }
  }

  // function isvalidURL(url) {
  //   const img = new Image()
  //   img.src = url
  //   console.log('image: ', img)
  //   if (img.width === 0) {
  //     return './assets/images/image-not-found.png'
  //   }
  //   return url
  // }
  function isYouTubeUrl(url) {
    const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/
    return regex.test(url)
  }
  function handleMouseOver() {
    if (!showVideoPrev && enableMouseOverPreview) {
      setMouseOverPreview(true)
    }
  }
  // console.log(
  //   'Article item: ',
  //   item,
  //   'RCPID: ',
  //   rcpid,
  //   'LIKED: ',
  //   whatsNewData,
  //   'STATUS: ',
  //   likeStatus,
  // )
  return (
    <Card variant="outlined" className={classes.itemCardContainer}>
      <>
        {isYouTubeUrl(item?.link) ? (
          <>
            <VideoPlayer
              idx={idx}
              setShowVideoPrev={setShowVideoPrev}
              mouseOverPreview={mouseOverPreview}
              setMouseOverPreview={setMouseOverPreview}
              assetUrl={item?.link}
            />
            <Box
              className={classes.previewDisplay}
              onMouseEnter={handleMouseOver}
            >
              <CardMedia
                className={clsx(classes.imageDisplay, {
                  [classes.active]: showVideoPrev,
                  [classes.inactive]: !showVideoPrev,
                })}
                image={image}
                title={item.imageAlt}
              >
                {item.type.toLowerCase() === 'video' && (
                  <Box className={classes.videoDurationTag}>05:30</Box>
                )}
              </CardMedia>
            </Box>
          </>
        ) : (
          <CardMedia
            className={classes.image}
            image={image}
            title={item.imageAlt}
          >
            {item.type === 'video' && (
              <Box className={classes.videoDurationTag}>05:30</Box>
            )}
          </CardMedia>
        )}
      </>

      <CardActions>
        <Box className={classes.itemActionsContainer}>
          <Button
            className={classes.articleLinkButton}
            onClick={() => history.push(`/WhatsNewArticle/${item.title}`)}
          >
            {item.type.toLowerCase() === 'video' ? (
              <>
                <PlayCircleFilledWhiteIcon className={classes.playIcon} />
                &nbsp;see more
              </>
            ) : (
              <>
                <ChromeReaderModeIcon />
                &nbsp;read more
              </>
            )}
          </Button>
          <Button
            onClick={handleLikeAction}
            className={classes.likeCountContainer}
          >
            {liked ? (
              <>
                <Text variant="body" className={classes.likeData}>
                  Likes:&nbsp;{numOflikes}
                </Text>
                <ThumbUpOnAltIcon className={classes.thumbIcon} />
              </>
            ) : (
              <>
                <Text variant="body" className={classes.likeData}>
                  Likes:&nbsp;{numOflikes}
                </Text>
                <ThumbUpOffAltIcon className={classes.thumbIcon} />
              </>
            )}
          </Button>
        </Box>
      </CardActions>

      <CardContent>
        <Text variant="h4" className={classes.itemTitle}>
          <b>{item.title}</b>
        </Text>
        <Divider />

        <Text variant="body1" className={classes.itemDescription}>
          {item.description}
        </Text>
        <Divider />
        <Box className={classes.tagList}>
          {item.tags.slice(0, 3).map((tag) => (
            <Chip className={classes.tagItem} key={`${tag}$`} label={tag} />
          ))}
          {item.tags.length > 3 && (
            <Chip className={classes.tagItem} key="last$" label="more..." />
          )}
        </Box>
      </CardContent>
    </Card>
  )
}

export default WhatsNewCard
