import AppsIcon from '@material-ui/icons/Apps'
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus'
import DashboardIcon from '@material-ui/icons/Dashboard'
import NewsIcon from '@material-ui/icons/ChromeReaderMode'
import Apps from '../features/apps/Apps'
import Dashboard from '../features/dashboard/Dashboard'
import Maps from '../features/maps/MyMap'
import News from '../features/news/News'
import Settings from '../features/settings/Settings'
// eslint-disable-next-line no-unused-vars
import SeeAllAppsPageMobile from '../features/apps/Pages/AllAppsPage/SeeAllAppsMobile/SeeAllAppsMobile'
import ViewAppInfoMobile from '../features/apps/Pages/AllAppsPage/ViewAppInfoMobile/ViewAppInfoMobile'

import InfoPage from '../features/apps/Pages/ForYouPage/SponsoredPage/InfoPage'
import ViewAppInfoPage from '../features/apps/Pages/AllAppsPage/AppInfoPage/AppInfoPage'
import ViewArticlePage from '../features/news/Pages/ViewArticlePage/ViewArticlePage'
import NewsSourcePage from '../features/news/Pages/NewsSourcePage/NewsSourcePage'
import ManageNewsMobile from '../features/news/Pages/ManageNewsMobile/ManageNewsMobile'
import ManageWidgetsMobile from '../features/dashboard/Pages/ManageWidgetsMobile/ManageWidgetsMobile'
import RestaurantListMobile from '../features/maps/sections/Restaurant/RestaurantListMobile/RestaurantListMobile'
import AdminPage from '../features/admin/AdminPage'
import NearesetStopsMobile from '../features/maps/mobile/NearestStopsMobile/NearestStopsMobile'
import CompensationHistory from '../features/dashboard/Pages/CompensationHistory/CompensationHistory'
import CompensationSingleHistory from '../features/dashboard/Pages/CompensationHistory/CompensationSingleHistory'
import MapPanelTwo from '../features/maps/sections/Map/MapPanelTwo'

import WhatsNewPage from '../features/apps/Pages/WhatsNewPage/WhatsNewPage'
import WhatsNewItemPage from '../features/apps/Pages/WhatsNewPage/WhatsNewItemPage'
export const navigation = {
  routes: [
    {
      path: '/',
      title: 'Dashboard',
      component: Dashboard,
      Icon: DashboardIcon,
      exact: true,
    },
    {
      path: '/ManageWidgetsMobile',
      title: 'ManageWidgetsMobile',
      component: ManageWidgetsMobile,
      Icon: DashboardIcon,
    },
    {
      path: '/apps',
      title: 'Apps',
      component: Apps,
      Icon: AppsIcon,
    },
    {
      path: '/news',
      title: 'News',
      component: News,
      Icon: NewsIcon,
    },
    {
      path: '/ManageNewsMobile',
      title: 'ManageNewsMobile',
      component: ManageNewsMobile,
      Icon: NewsIcon,
    },
    {
      path: '/maps',
      title: 'Maps',
      component: Maps,
      Icon: DirectionsBusIcon,
    },
    {
      path: '/maps-2',
      title: 'Maps',
      component: MapPanelTwo,
      Icon: null,
    },
    {
      path: '/settings',
      title: 'Settings',
      component: Settings,
      Icon: null,
    },
    {
      path: '/infoPage',
      title: 'View Info About A Selected Category',
      component: InfoPage,
      Icon: null,
    },
    {
      path: '/ViewAppInfoPage',
      title: 'View App Info Page',
      component: ViewAppInfoPage,
      Icon: null,
    },
    {
      path: '/ViewArticlePage',
      title: 'ViewArticlePage',
      component: ViewArticlePage,
      Icon: null,
    },
    {
      path: '/SeeAllAppsPageMobile',
      title: 'SeeAllAppsPageMobile',
      component: SeeAllAppsPageMobile,
      Icon: null,
    },
    {
      path: '/ViewAppInfoPageMobile',
      title: 'ViewAppInfoMobile',
      component: ViewAppInfoMobile,
      Icon: null,
    },
    {
      path: '/NewsSourcePage',
      title: 'News Source',
      component: NewsSourcePage,
      Icon: null,
    },
    {
      path: '/RestaurantListMobile',
      title: 'RestaurantListMobile',
      component: RestaurantListMobile,
      Icon: null,
    },
    {
      path: '/AdminPage',
      title: 'Admin Page',
      component: AdminPage,
      Icon: null,
    },
    {
      path: '/NearestStopsMobile',
      title: 'Nearest Stops Mobile',
      component: NearesetStopsMobile,
      Icon: null,
    },
    {
      path: '/CompensationHistory/:date',
      title: 'Single Compensation History',
      // component: CompensationHistory,
      component: CompensationSingleHistory,
      Icon: null,
    },
    {
      path: '/CompensationHistory',
      title: 'Compensation History',
      component: CompensationHistory,
    },
    {
      path: '/WhatsNew',
      title: 'What`s New',
      component: WhatsNewPage,
      Icon: null,
    },
    {
      path: '/WhatsNewArticle/:title',
      title: 'What`s New',
      component: WhatsNewItemPage,
      Icon: null,
    },
  ],
}
