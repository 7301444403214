import { makeStyles } from '@material-ui/core/styles'

export const CompensationHistoryStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    paddingTop: 64,
    paddingLeft: 54,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 64,
      paddingLeft: 0,
      paddingBottom: 16,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56,
      paddingLeft: 0,
      paddingBottom: 16,
    },
  },
  navbar: {
    width: '100%',
    backgroundColor: theme.palette.paper.tertiary,
    // backgroundColor: theme.color.lightGray,
    height: 60,
    alignItems: 'center',
    alignContent: 'center',
    paddingLeft: 8,
    borderBottom: `1px solid ${theme.color.gray}`,
  },
  backButton: {
    backgroundColor: theme.palette.paper.tertiary,
    // backgroundColor: theme.color.lightGray,
  },
  backArrow: {
    height: 32,
    width: 32,
    border: `2px solid ${theme.palette.primary.headerText}`,
    borderRadius: '100%',
    marginRight: theme.spacing(1),
  },
  body: {
    margin: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(5, 0),
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0, 5),
    },
  },
  box: {
    borderRadius: theme.spacing(1),
    boxShadow: '0 0 8px rgba(0,0,0,0.4)',
    width: 520,
    height: '100%',
    padding: 0,
    margin: theme.spacing(0, 5, 5, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.paper.primary,
    borderRadius: theme.spacing(0, 0, 1, 1),
  },
  headerBar: {
    width: '100%',
    color: theme.color.white,
    backgroundColor: theme.color.blue,
    borderRadius: theme.spacing(1, 1, 0, 0),
    textAlign: 'center',
    padding: 8,
  },
  profilePicture: {
    width: 100,
    height: 100,
    borderRadius: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    objectFit: 'cover',
  },
  keyGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
  },
  divider: {
    width: '100%',
    margin: theme.spacing(1, 0),
    backgroundColor: theme.palette.components.icon,
  },
  filterIcon: {
    padding: 4,
    marginRight: 8,
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.paper.secondary,
    border: `1px solid ${theme.color.gray}`,
    '&:hover': {
      backgroundColor: theme.palette.button.hover,
    },
  },
  bodyText: {
    fontWeight: 500,
  },
  tableHeaderText: {
    lineHeight: 1.2,
    fontWeight: 700,
  },
  frontBox: {
    justifyItems: 'flex-start',
  },
  lastBox: {
    justifyItems: 'flex-end',
  },
  date: {
    color: theme.palette.components.linkText,
  },
  row: {
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 1),
    },
  },
  oddRow: {
    backgroundColor: theme.palette.paper.primary,
  },
  evenRow: {
    backgroundColor: theme.palette.paper.secondary,
  },
  dateFilterContent: {
    margin: 'auto',
    height: '100%',
    justifyContent: 'space-evenly',
  },
  dateFilterGrid: {
    textAlign: 'center',
    width: '80%',
    height: '40%',
  },
  dateFilterPaper: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.paper.primary,
    height: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  dateTextField: {
    width: theme.spacing(20),
    background:
      theme.palette.type === 'dark'
        ? `linear-gradient(90deg, ${theme.color.darkSlateGray} 72%, ${theme.color.gray} 73%)`
        : `linear-gradient(90deg, ${theme.color.lighterWhitesmoke} 72%, ${theme.color.whitesmoke} 73%)`,
    borderRadius: theme.spacing(0.5),
  },
  paginationGrid: {
    paddingTop: 4,
  },
  pageCircle: {
    '&:focus-visible': {
      outline: `1px solid ${theme.palette.button.focusBorder}`,
      boxShadow: `0 0 0 3px ${theme.palette.button.focusOutline}`,
    },
  },
  key: {
    fontWeight: 700,
  },
  // single compensation page styles
  salaryChangeBox: {
    padding: theme.spacing(0, 6),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0),
    },
  },
}))
