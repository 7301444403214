import { makeStyles, createStyles } from '@material-ui/core/styles'

const whatsNewPageStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingLeft: '60px',
      paddingRight: '5px',
      paddingBottom: 50,
      background: theme.palette.components.newsBackground,
      [theme.breakpoints.down(769)]: {
        paddingLeft: '5px',
      },
      [theme.breakpoints.up(1023)]: {
        paddingLeft: '5px',
      },
    },
    tagList: {
      textAlign: 'center',
      flex: '0 0 100%',
    },
    tagItem: {
      marginLeft: '8px',
      marginBottom: '0px',
      [theme.breakpoints.down(769)]: {
        marginBottom: '5px',
      },
    },
    title: {
      margin: '20px',
      alignSelf: 'center',
    },
    titleDivider: {
      borderTop: '2px solid',
      marginBottom: '20px',
      color:
        theme.palette.type === 'light'
          ? theme.palette.base.gray
          : theme.palette.base.white,
    },
    // article item page styles
    itemPageLayout: {
      paddingLeft: '57px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: '0px',
      },
    },
    pageBody: {
      padding: '30px',
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
      alignItems: 'center',
      marginTop: '64px',
    },
    videoContent: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px',
    },
    videoPlayer: {
      borderRadius: '20px',
    },
    textContent: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: '1000px',
    },

    itemPageheader: {
      background: theme.palette.paper.tertiary,
      position: 'sticky',
      top: '64px',
      [theme.breakpoints.down('sm')]: {
        top: '56px',
      },
    },

    heroImageContainer: {
      display: 'flex',
      flexDirection: 'column',
      color:
        theme.palette.type === 'light'
          ? theme.palette.base.gray
          : theme.palette.base.white,
      marginBottom: '20px',
    },
    heroImage: {
      borderRadius: 20,
      width: '100%',
      maxWidth: '1000px',
    },
  }),
)

export default whatsNewPageStyles
