import React from 'react'
// TODO: load seek link every time when mouse is over.
// to prevent video reaching end. replace normal link for initial preview
const VideoPlayer = ({
  assetUrl,
  setShowVideoPrev,
  mouseOverPreview,
  setMouseOverPreview,
  idx,
}) => {
  const iframeRef = React.useRef(null)
  const ytVideoId = assetUrl.split('=')
  const ytVideoURL = `https://www.youtube.com/embed/${ytVideoId[1]}?rel=0&amp;autoplay=1&mute=1&controls=0&autohide=1&showinfo=0&modestbranding=1&enablejsapi=1`
  const [ytvUrl, setvideoUrl] = React.useState('')
  // const [animPreview, setAnimPreview] = React.useState(false)
  const previewStartTime = (order) => (order === 0 ? 1500 : order * 9800)

  // TODO: disable on hover anim if url is not set or iframe not loaded!
  React.useEffect(() => {
    let fadeInTimer
    let fadeOutTimer
    // let clearVideoUrlTimer
    if (iframeRef.current) {
      // Access the iframe element
      const iframe = iframeRef.current

      // Add an event listener for the 'load' event
      iframe.addEventListener('load', () => {
        // fade out preview image when video loaded // add delay to skip youtube logo

        fadeOutTimer = setTimeout(() => {
          setShowVideoPrev(true)
        }, 1300)

        // fade in preview image after preview duration
        fadeInTimer = setTimeout(() => {
          setShowVideoPrev(false)
        }, 6000)
        // // after preview image fades in remove video url from iframe
        // clearVideoUrlTimer = setTimeout(() => {
        //   setShowVideoPrev(false)
        //   setvideoUrl('')
        // }, 7000)
      })
    }
    return () => clearTimeout(fadeInTimer, fadeOutTimer)
  }, [])

  // when page is loaded set the video url for the iframe and play
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setvideoUrl(ytVideoURL)
    }, previewStartTime(idx))
    return () => clearTimeout(timer)
  }, [])

  React.useEffect(() => {
    if (mouseOverPreview) {
      console.log('mouse ofver for, ', idx)
      setShowVideoPrev(true)
      setTimeout(() => {
        setShowVideoPrev(false)
        setMouseOverPreview(false)
      }, 3000)
    }
  }, [mouseOverPreview])

  // TODO move mouse enter and leave trigger functions to parent element image
  return (
    <div>
      <iframe
        allow=""
        style={{ pointerEvents: ' none' }}
        ref={iframeRef}
        height={180}
        width={350}
        src={ytvUrl}
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  )
}

export default VideoPlayer
