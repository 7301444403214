import { makeStyles } from '@material-ui/core/styles'

export const SearchDialogStyles = makeStyles((theme) => ({
  appBarIcon: {
    color: `${theme.palette.components.topBarIcon} !important`,
    fontSize: 24,
  },
  line: {
    height: '2px',
    width: '100%',
    backgroundColor: theme.palette.secondary.headerText,
    border: 'none',
  },
  alertMenu: {
    marginTop: 3,
    marginLeft: 5,
  },
  flex: {
    display: 'flex',
  },
  widgetContainer: {
    width: 600,
    height: 'auto',
    textAlign: 'left',
    alignContent: 'center',
    // backgroundColor: theme.palette.backgroundColor.main,
    [theme.breakpoints.down('md')]: {
      width: 550,
    },
    [theme.breakpoints.down('lg')]: {
      width: 500,
    },
    [theme.breakpoints.down('sm')]: {
      width: 350,
    },
    [theme.breakpoints.down('xs')]: {
      width: 300,
    },
  },
  paper: {
    padding: '24px',
    backgroundColor: theme.palette.backgroundColor.secondary,
  },
  modalContainer: {
    // backgroundColor: theme.palette.backgroundColor.main,
    height: '100%',
  },
  select: {
    minWidth: 150,
    width: '100%',
  },
  input: {
    width: '100%',
  },
  searchButton: {
    marginTop: 16,
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    alignContent: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hintsGrid: {
    display: 'flex',
    flexWrap: 'none',
    boxSizing: 'border-box',
  },
}))

export default SearchDialogStyles
