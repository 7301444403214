import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  listContainer: {
    height: '411px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },

  itemCardContainer: {
    height: '100%',
    width: '350px',
    borderRadius: 16,
    boxShadow:
      'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
  },
  imageContainer: {
    position: 'relative',
    maxHeight: '250px',
  },
  image: {
    position: 'relative',
    height: '180px',
    padding: '0 !important',
    zIndex: 1,

    '&:hover': {
      cursor: 'pointer',
    },
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    marginBottom: '5px',
  },

  '@keyframes vanish': {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0,
    },
  },
  '@keyframes emerge': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  videoContainer: {
    position: 'relative',
  },
  previewDisplay: {
    position: 'relative',
  },
  imageDisplay: {
    height: '180px',
    width: '100%',
    position: 'absolute',
    zIndex: 4,
    top: -185,
    right: 0,
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  active: {
    animation: '$vanish 2s forwards',
  },
  inactive: {
    animation: '$emerge 2s forwards',
  },
  videoOverlayImage: {
    height: '180px',
    padding: '0 !important',
  },

  videoDurationTag: {
    backgroundColor: 'hsla(120deg, 0%, 25%, 0.9)',
    color: theme.palette.base.white,
    borderRadius: '5px',
    padding: '2px 4px 2px 4px',
    position: 'absolute',
    right: '5px',
    bottom: '8px',
  },
  articleLinkButton: {
    marginLeft: '3px',
    backgroundColor: theme.color.rutgersRed,
    color: theme.palette.base.white,
    width: '150px',
    diplsay: 'flex',
  },
  itemTitle: {
    marginBottom: '8px',
  },
  itemDescription: {
    marginTop: '8px',
    marginBottom: '8px',
    overflow: 'hidden',
    display: '-webkit-box',

    '-webkit-line-clamp': 3,
    'line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  playIcon: {
    color: theme.palette.base.white,
  },
  itemListGrid: {
    height: '400px',
  },
  itemActionsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tagItem: { height: '18px', marginRight: '4px', marginTop: '4px' },
  tagList: {
    marginTop: '8px',
  },
  likeCountContainer: {
    cursor: 'pointer',
    position: 'relative',
    borderRadius: 20,
    backgroundColor: theme.palette.paper.secondary,
    display: 'flex',

    alignItems: 'center',
    justifyContent: 'space-between',
    height: '26px',
    marginRight: '2px',
    border: `2px solid ${theme.palette.base.lightGray}`,
  },
  likeData: {
    backgroundColor: theme.palette.paper.secondary,
    marginLeft: '2px',
    marginRight: '40px',
  },
  thumbIcon: {
    position: 'absolute',
    right: -2,
    width: '25px',
    height: '25px',
    alignSelf: 'center',
    borderRadius: 50,
    backgroundColor: theme.palette.paper.secondary,
    padding: '2px',
    border: `2px solid ${theme.palette.base.lightGray}`,
  },
}))

export default styles
