import * as React from 'react'
import {
  FormControl,
  Select,
  Paper,
  Box,
  Typography,
  Checkbox,
  ListItemText,
  IconButton,
  Tooltip,
  MenuItem,
} from '@material-ui/core'

import Backspace from '@material-ui/icons/Backspace'
import PropTypes from 'prop-types'
import { filterStyles } from './filterStyles'

export default function FilterMenu({ filterMenuProps }) {
  const [selectedFilterItems, setSelectedFilterItems] = React.useState([])
  const headerColor = 'ffff'
  const classes = filterStyles({ headerColor })

  function splitCamelCase(word) {
    return word.replace(/[A-Z]/g, ' $&').trim()
  }
  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    // remove default value from list
    const indexOfDefault = value.indexOf(filterMenuProps.defaultMenuItem)
    if (indexOfDefault > -1) {
      value.splice(indexOfDefault, 1)
    }

    let filterKeys = []

    value.forEach((item) => {
      if (filterKeys.findIndex((o) => o === item) >= 0) {
        filterKeys = filterKeys.filter((x) => x === item)
      } else {
        filterKeys.push(item)
      }
    })
    setSelectedFilterItems(filterKeys)
    filterMenuProps.onFilterRequest(filterKeys)
  }
  function handleClearAll() {
    setSelectedFilterItems([])
    filterMenuProps.onFilterRequest([])
  }

  return (
    <Box className={classes.filterWrapper}>
      <Paper
        variant="outlined"
        component="form"
        className={classes[filterMenuProps.wrapperClassName]}
      >
        {filterMenuProps.label ? (
          <Box className={classes.filterHeader}>
            <Typography variant="body1">{filterMenuProps.label}</Typography>
          </Box>
        ) : null}
        <Box className={classes[filterMenuProps.containerClassName]}>
          <FormControl
            variant="standard"
            style={{
              width: '100px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Select
              sx={{ '& .MuiSvgIcon-root': { color: '#003366' } }}
              labelId="event-filter-menu-select-label"
              id="event-filter-menu-select"
              value={
                selectedFilterItems.length
                  ? selectedFilterItems
                  : [filterMenuProps.defaultMenuItem]
              }
              onChange={handleChange}
              disableUnderline
              multiple
              displayEmpty
              renderValue={(selected) =>
                selected.length < 2 ? (
                  selected.map((x) => (
                    <Typography
                      className={classes.selectedFilterItemName}
                      key={x}
                    >
                      {x}
                    </Typography>
                  ))
                ) : (
                  <Typography className={classes.selectedFilterItemName}>
                    {` ${selected.length} selected`}
                  </Typography>
                )
              }
            >
              {filterMenuProps.menuItemList.map((menuItem) => (
                <MenuItem key={menuItem.id} value={menuItem.item}>
                  <Checkbox
                    checked={
                      selectedFilterItems.findIndex(
                        (item) => item === menuItem.item,
                      ) >= 0
                    }
                  />
                  <ListItemText primary={splitCamelCase(menuItem.item)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Paper>

      {selectedFilterItems.length ? (
        <Box className={classes.eventFilterClearIconWrapper}>
          <Tooltip title="Clear filter">
            <IconButton
              onClick={handleClearAll}
              id="clear filter"
              aria-label="clear filter"
            >
              <Backspace />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        <Box style={{ width: '38px' }} />
      )}
    </Box>
  )
}

FilterMenu.propTypes = {
  filterMenuProps: PropTypes.shape({
    label: PropTypes.string,
    menuItemList: PropTypes.array.isRequired,
    defaultMenuItem: PropTypes.string.isRequired,
    onFilterRequest: PropTypes.func.isRequired,
    containerClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
    headerColor: PropTypes.string,
  }),
}
