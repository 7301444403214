import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  dialog: {
    width: '70vw',
    maxWidth: '100%',
    height: '70vh',
    padding: '0px',
    overflowX: 'hidden',
    backgroundColor: theme.palette.paper.secondary,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100px',
      width: '90vw',
      height: '60vh',
    },
  },
  closeIcon: {
    color: theme.palette.base.rutgersRed,
  },
}))

export default styles
