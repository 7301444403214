/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

/*
    This file contains the redux tool kit code for the state that the Whats New Page uses.
*/

// update POST like array endpoint https://test-my.rutgers.edu/myrutgersapi/widgets/whatsNew/addToLikedList?articleId=0
// remove ike POST endpoint https://test-my.rutgers.edu/myrutgersapi/widgets/whatsNew/removeFromLikedList?articleId=5

export const fetchWhatsNewArticles = createAsyncThunk(
  'fetchWhatsNewArticles',
  async () => {
    const rcpid = localStorage.getItem('rutgersEduRCPID')
    const res = await axios.get(`${process.env.REACT_APP_WHATS_NEW_ENDPOINT}`)
    const data = { data: res.data, rcpid }
    return data
  },
)

const addLikeEndpoint = process.env.REACT_APP_WHATS_NEW_ARTICLE_LIKE_ENDPOINT
const removeLikeEndpoint =
  process.env.REACT_APP_WHATS_NEW_ARTICLE_REMOVE_LIKE_ENDPOINT
export const updateArticleLikedByUser = createAsyncThunk(
  'updateArticleLikedByUser',
  async ({ articleID, add }) => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rutgersEduRCPID')
    const useEdnpoint = add ? addLikeEndpoint : removeLikeEndpoint
    const res = axios.post(
      `${useEdnpoint}?articleId=${articleID}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    )
    // if response is ok then just return article id maybe
    console.log(
      'ARTICLE liked no.:  ',
      articleID,
      'returned: ',
      `${useEdnpoint}?articleId=${articleID}`,
    )
    const data = { data: res.data, articleID, add }
    return data
  },
)
// This is the initial state object the reducers and extraReducers will change based on whatever we need to change it to. It is required when creating the createSlice object.
const initialState = {
  status: 'idle',
  likedStatus: 'idle',
  data: {
    articles: [],
    likedArticles: [],
  },
}

const whatsNewSlice = createSlice({
  name: 'whatsNewPage',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchWhatsNewArticles.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchWhatsNewArticles.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchWhatsNewArticles.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      // populate liked state list
      state.data.articles = action.payload.data
      const id = parseInt(action.payload.rcpid, 10)
      action.payload.data.articles.forEach((element) => {
        if (element.likedList.includes(id)) {
          state.data.likedArticles.push(element.id)
        }
      })
    },
    [updateArticleLikedByUser.rejected]: (state) => {
      state.likedStatus = 'error'
    },
    [updateArticleLikedByUser.pending]: (state) => {
      state.likedStatus = 'pending'
    },
    [updateArticleLikedByUser.fulfilled]: (state, action) => {
      state.likedStatus = 'fulfilled'
      // implemenet push pop logic based on action
      if (action.payload.add) {
        state.data.likedArticles.push(action.payload.articleID)
      } else {
        const newState = state.data.likedArticles.filter(
          (item) => item !== action.payload.articleID,
        )
        state.data.likedArticles = newState
      }
    },
  },
})
// List of selector functions that can be used to access state inside of the DidYouKnow.js Component.
export const selectWhatsNewData = (state) => state.whatsNew.data
export const selectWhatsNewLikedArticles = (state) =>
  state.whatsNew.data.likedArticles
export const selectWhatsNewStatus = (state) => state.whatsNew.status
export const selectWhatsNewLikedStatus = (state) => state.whatsNew.likedStatus

export default whatsNewSlice.reducer
