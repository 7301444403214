import React from 'react'
import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import { filterStyles } from './filterStyles'

import FilterMenu from './FilterMenu'

export default function FilterBar({
  isMobile,
  categories,
  types,
  onFilteringCategory,
  onFilteringType,
}) {
  const classes = filterStyles()
  // optimize these conversions and sort into a single function
  const sortedCategories = categories.sort((a, b) =>
    a.toLowerCase().localeCompare(b.toLowerCase()),
  )
  const categoryItems = sortedCategories.map((value) => ({ item: value }))
  const typeItems = types.map((value) => ({ item: value }))

  const menuProps = [
    {
      label: '',
      menuItemList: typeItems,
      defaultMenuItem: 'Type',
      onFilterRequest: onFilteringType,
      containerClassName: 'typeFilterContainer',
      wrapperClassName: 'typeFilterWrapper',
      headerColor: 'linear-gradient(135deg, #0066CC, #003366);',
    },
    {
      label: '',
      menuItemList: categoryItems,
      defaultMenuItem: 'Category',
      onFilterRequest: onFilteringCategory,
      containerClassName: 'categoryFilterWrapper', // revise this classname!
      wrapperClassName: 'categoryFilterWrapper',
      headerColor: 'linear-gradient(135deg, #0066CC, #003366);',
    },
  ]

  return (
    <Box
      className={
        isMobile ? classes.mobileFiltersWrapper : classes.filtersWrapper
      }
    >
      {menuProps?.map((menuProp) => (
        <FilterMenu key={menuProp.label} filterMenuProps={menuProp} />
      ))}
    </Box>
  )
}

FilterBar.propTypes = {
  categories: PropTypes.array,
  types: PropTypes.array,
  onFilteringType: PropTypes.func,
  onFilteringCategory: PropTypes.func,
  isMobile: PropTypes.bool,
}
