import React from 'react'
import { Box, Card, Divider, Typography as Text } from '@material-ui/core'
import WhatsNewSearchBarStyles from './whatsNewSearchBarStyles'
import SearchBar from '../../../../../dashboard/DashboardComponents/SearchBar/SearchBar'
import FilterBar from './filter/FilterBar'
// import CheckIsMobile from '../../../../../../../utils/isMobileHook'
export default function WhatsNewSearchBar({
  categories,
  types,
  onFilteringCategory,
  onFilteringType,
  value,
  onCancelSearch,
  onChange,
}) {
  const classes = WhatsNewSearchBarStyles()
  // const isMobile = CheckIsMobile()
  return (
    <Box className={classes.searchBarWrapper}>
      <Card variant="outlined" className={classes.sidePanel}>
        <SearchBar
          placeholder="search"
          className={classes.searchBar}
          value={value}
          onChange={onChange}
          onCancelSearch={onCancelSearch}
          webViewMode
          onRequestSearch={() => console.log('searchQuery')}
          variant="outlined"
        />
        <Card variant="outlined" className={classes.filterBar}>
          <Box className={classes.filterBarHeader}>Filters</Box>
          <Box className={classes.filterBarContent}>
            <FilterBar
              isMobile={false}
              categories={categories}
              types={types}
              onFilteringCategory={onFilteringCategory}
              onFilteringType={onFilteringType}
            />
          </Box>
        </Card>
        <Divider className={classes.divider} />
        <Box className={classes.sidePanelText}>
          <Text variant="body">Total Articles: 7</Text>
        </Box>
      </Card>
    </Box>
  )
}
