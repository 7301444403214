import { makeStyles, createStyles } from '@material-ui/styles'

export const AppInfoPageStyles = makeStyles((theme) =>
  createStyles({
    container: {
      flex: 1,
      background: theme.palette.components.newsBackground,
      overflow: 'auto',
      overflowX: 'hidden',
      overscrollBehavior: 'contain',
      height: '100vh',

      width: '100%',
      paddingBottom: '24px',
      paddingTop: '16px',
      paddingLeft: '55px',
      paddingRight: '20px',
    },
    topContainer: {
      marginTop: '56px',
      marginLeft: '10%',
    },
    iconContainer: {
      width: 'auto',
      height: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '15px',
    },
    headingContainer: {
      paddingLeft: '16px',
    },
    appNameText: {
      fontSize: '19',
      color: theme.palette.primary.headerText,
      textAlign: 'start',
      fontWeight: '600',
    },
    categoryText: {
      color: theme.palette.secondary.headerText,
      fontSize: '16px',
      textAlign: 'start',
      fontWeight: '500',
    },
    iconButton: {
      position: 'relative',
      zIndex: 1000,
      fontSize: '20px',
      color: theme.palette.base.lightGray,
      margin: '10px',
      marginTop: '13px',
    },
    selectedFavoriteIconButton: {
      position: 'relative',
      zIndex: 1000,
      fontSize: '20px',
      color: theme.color.rutgersRed,
    },
    iconButtonBorder: {
      position: 'relative',
      zIndex: 1000,
      fontSize: '20px',
      color: theme.color.rutgersRed,
    },
    roundedButton: {
      borderRadius: '4px',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.backgroundColor.secondary,
      height: 25,
      fontWeight: '600',
      marginRight: theme.spacing(2),
      '&:hover': {
        backgroundColor: theme.palette.button.hover,
        // color: theme.color.rutgersRed,
        cursor: 'pointer',
      },
    },
    descriptionContainer: {
      marginTop: 16,
      marginRight: '20%',
    },
    pageToggleChip: {
      backgroundColor: theme.color.rutgersLightPink,
      border: `1.5px solid ${theme.color.rutgersRed}`,
      color: 'black',
      borderRadius: '20px',
      marginRight: '7px',
      marginTop: '7px',
      fontWeight: '600',
      height: 25,
    },
    descriptionHeaderText: {
      color: theme.palette.primary.headerText,
      textAlign: 'start',
      fontSize: 18,
      fontWeight: '600',
    },
    infoTextContainer: {
      marginTop: 8,
      padding: 8,
    },
    infoText: {
      color: theme.palette.secondary.headerText,
      textAlign: 'start',
      fontSize: 13,
      fontWeight: '350',
    },
    card: {
      flexShrink: 0,
      height: '350px',
      width: '570px',
      margin: '4px',
    },
    media: {
      height: '100%',
      width: '100%',
    },
    categoryCardSliderContainer: {
      // display: 'flex',
      // width: '100%',
      marginTop: '20px',
      justifyContent: 'center',
      alignItems: 'center',
      overflowY: 'hidden',
    },
    relatedAppsContainer: {
      margin: '30px',
      paddingTop: '20px',
    },
    relatedAppsText: {
      color: theme.palette.primary.headerText,
      fontSize: 24,
      textAlign: 'start',
      marginLeft: '6%',
      marginBottom: '-20px',
    },
    imageStyle: {
      background: 'none',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    sliderContainer: {
      marginBottom: '10px',
      height: '600px',
    },
    imgStyles: {
      height: 'auto',
    },
    carouselProvider: {
      maxHeight: '2000px',
      maxWidth: '600px',
    },
    slides: {
      height: '350px',
      width: '600px',
      paddingBottom: '0px !important',
    },
    img: {
      display: 'block',
      maxHeight: '1000px',
      maxWidth: '1000px',
      width: 'auto',
      height: 'auto',
    },

    buttonStyle: {
      color: theme.palette.base.darkGray,
      borderRadius: 100,
      outlineWidth: 0,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      '&:disabled': {
        color: theme.palette.base.gray,
      },
      '&:hover': {
        transform: 'scale(1.1)',
      },
    },
    dotGroup: {
      maxWidth: '150px',
    },
    backArrow: {
      position: 'relative',
      left: 4,
      top: 1,
    },
    forwardArrow: {
      position: 'relative',
      top: 1,
    },
  }),
)

export default AppInfoPageStyles
