import React from 'react'
import { YouTubeEmbed } from 'react-social-media-embed'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useSelector, useDispatch } from 'react-redux'
import {
  Box,
  Button,
  Chip,
  Divider,
  Typography as Text,
} from '@material-ui/core'
import ThumbUpOnAltIcon from '@material-ui/icons/ThumbUpAlt'
import ThumbUpOffAltIcon from '@material-ui/icons/ThumbUpAltOutlined'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import {
  selectWhatsNewData,
  updateArticleLikedByUser,
  selectWhatsNewLikedArticles,
} from './WhatsNewPageSlice'
import { getImageFromFirebaseWithHook } from '../../helpers'
import whatsNewPageStyles from './WhatsNewPageStyle'
import styles from './components/itemCard/WhatsNewItemCardStyles'
// TO DO solve re load data missing issue
const WhatsNewItemPage = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const title = props.location.pathname.split('/')[2]
  const classes = whatsNewPageStyles()

  const likeClasses = styles()
  const whatsNewData = useSelector(selectWhatsNewData)
  const [numOflikes, setnumOflikes] = React.useState(0)
  const [itemdata, setItemdata] = React.useState(null)
  const [image, setImage] = React.useState('')
  const [liked, setliked] = React.useState(false)
  const rcpid = localStorage.getItem('rcpid')
  const likedArticles = useSelector(selectWhatsNewLikedArticles)
  console.log('itempage', title, itemdata)
  // TODO!! move like button into a standalone component

  React.useEffect(() => {
    setnumOflikes(likedArticles.length)
    if (likedArticles.includes(itemdata?.id)) {
      setliked(true)
    }
  }, [likedArticles])
  React.useEffect(() => {
    const article = whatsNewData?.articles?.articles?.filter(
      (item) => item.title === title,
    )

    if (article) {
      getImageFromFirebaseWithHook(article[0].image, setImage)
      setItemdata(article[0])
    }
  }, [whatsNewData])

  React.useEffect(() => {
    setnumOflikes(itemdata?.likedList.length)
    if (itemdata?.likedList.includes(parseInt(rcpid, 10))) {
      setliked(true)
    }
  }, [itemdata, rcpid])
  function handleLikeAction() {
    if (!liked) {
      dispatch(updateArticleLikedByUser({ articleID: itemdata.id, add: true }))
      setliked(!liked)
      // setnumOflikes((prevNum) => prevNum + 1)
      console.log('liked')
    } else if (liked) {
      console.log('disliked')
      dispatch(updateArticleLikedByUser({ articleID: itemdata.id, add: false }))
      setliked(!liked)
      // setnumOflikes((prevNum) => prevNum - 1)
    }
  }

  function isYouTubeUrl(url) {
    const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/
    return regex.test(url)
  }
  // TODO implement  search based on title
  return (
    <Box className={classes.itemPageLayout}>
      <Box className={classes.itemPageheader}>
        <Button
          className={classes.cardImageButton}
          onClick={() => history.push(`/WhatsNew`)}
        >
          <ArrowBackIosIcon />
          <Text variant="h5">Back</Text>
        </Button>
        <Divider />
      </Box>
      <Box className={classes.pageBody}>
        <Box className={classes.heroImageContainer}>
          <img
            className={classes.heroImage}
            alt={itemdata?.imageAlt}
            src={image}
          />
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '8px',
            }}
          >
            <Text variant="caption">{itemdata?.imageAlt}</Text>
            <Button
              onClick={handleLikeAction}
              className={likeClasses.likeCountContainer}
            >
              {liked ? (
                <>
                  <Text variant="body" className={likeClasses.likeData}>
                    Likes:&nbsp;{numOflikes}
                  </Text>
                  <ThumbUpOnAltIcon className={likeClasses.thumbIcon} />
                </>
              ) : (
                <>
                  <Text variant="body" className={likeClasses.likeData}>
                    Likes:&nbsp;{numOflikes}
                  </Text>
                  <ThumbUpOffAltIcon className={likeClasses.thumbIcon} />
                </>
              )}
            </Button>
          </Box>
        </Box>
        <Box className={classes.textContent}>
          <Box className={classes.tagList}>
            {itemdata?.tags.map((tag) => (
              <Chip className={classes.tagItem} key={`${tag}$`} label={tag} />
            ))}
          </Box>
          <Text variant="h1" className={classes.title}>
            {title}
          </Text>
          <Divider className={classes.titleDivider} />
          <Box className={classes.videoContent}>
            {isYouTubeUrl(itemdata?.link) && image ? (
              <YouTubeEmbed
                // placeholderImageUrl={image}
                // width="100%"
                // height="580px"
                // style={{ border: 'solid 4px #37474F', borderRaidus: '20px' }}
                youTubeProps={{
                  // style: {  },
                  onReady: async (r) =>
                    (await r.target.getIframe()).addEventListener('load', () =>
                      console.log('IFRAME: ', r.target),
                    ),
                }}
                url={itemdata?.link}
              />
            ) : null}
          </Box>
          <Text variant="body1" style={{ marginBottom: '60px' }}>
            {' '}
            {itemdata?.description}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

export default WhatsNewItemPage
