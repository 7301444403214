import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import { PropTypes } from 'prop-types'
import { camelCaseToHuman, numberToMoney } from '../../../../../utils/utils'
import { CompensationHistoryStyles } from './CompensationHistoryStyles'

const SalaryChangeInfo = ({ item }) => {
  // takes single salary item and creates 5 key value rows
  // switch case grabs the key fields needed
  const final = []

  Object.keys(item).forEach((key) => {
    // collect KeyValueRow into an array
    switch (true) {
      case key === 'dateOfChange':
      case key === 'changePercent':
      case key === 'salaryPlan':
      case key === 'action':
      case key === 'reason':
        final.push(
          <KeyValueRow
            key={`${key} ${item[key]}`}
            left={key}
            right={item[key]}
          />,
        )
        break
      default:
        break
    }
  })
  // render all items in array
  return <>{final.map((x) => x)}</>
}

SalaryChangeInfo.propTypes = {
  item: PropTypes.object.isRequired,
}

const KeyValueRow = ({ left, right }) => {
  // takes two strings, key on left, value on right
  // renders them into one row
  const classes = CompensationHistoryStyles()

  // take camelCase values and make them human readable
  let formattedKey = ''
  switch (true) {
    case left === 'employeeID':
      formattedKey = 'Employee ID'
      break
    case left === 'dateofChange':
      formattedKey = 'Date of Change'
      break
    default:
      formattedKey = camelCaseToHuman(left)
  }

  return (
    <React.Fragment key={`${left} ${right}`}>
      <Grid item xs={6} className={classes.keyGrid}>
        <Typography variant="subtitle1" className={classes.key}>
          {formattedKey}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" className={classes.bodyText}>
          {right !== null ? right : 'n/a'}
          {left === 'changePercent' && '%'}
        </Typography>
      </Grid>
    </React.Fragment>
  )
}

KeyValueRow.propTypes = {
  left: PropTypes.string.isRequired,
  right: PropTypes.any,
}

const SingleRow = ({ v1, v2, v3, isHeader }) => {
  // v1, v2, and v3 are the string value that get passed
  // isHeader is optional and changes where the bold is placed
  const classes = CompensationHistoryStyles()
  const finalV2 = v2 !== null ? `$${numberToMoney(v2)}` : 'n/a'
  const finalV3 = v3 !== null ? `$${numberToMoney(v3)}` : 'n/a'

  return (
    <Grid item container alignItems="center" className={classes.row}>
      <Grid item xs={4}>
        <Typography variant="body1" className={classes.bodyText}>
          {!isHeader ? <b>{v1}</b> : v1}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body1" className={classes.bodyText}>
          {isHeader ? <b>{v2}</b> : finalV2}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body1" className={classes.bodyText}>
          {isHeader ? <b>{v3}</b> : finalV3}
        </Typography>
      </Grid>
    </Grid>
  )
}

SingleRow.propTypes = {
  v1: PropTypes.string.isRequired,
  v2: PropTypes.any,
  v3: PropTypes.any,
  isHeader: PropTypes.bool,
}

export { SalaryChangeInfo, SingleRow }
