import React from 'react'
import { Typography, Grid, Divider, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {
  SalaryChangeInfo,
  SingleRow,
} from './CompensationSingleHistoryComponents'
import {
  extractAfterLastSlash,
  calculateDifference,
} from './CompensationHistoryHelpers'
import { CompensationHistoryStyles } from './CompensationHistoryStyles'
import {
  fetchCompensationHistory,
  selectCHData,
  selectCHStatus,
} from './CompensationHistorySlice'

function CompensationSingleHistory() {
  const dispatch = useDispatch()
  const classes = CompensationHistoryStyles()
  const history = useHistory()
  const chData = useSelector(selectCHData)
  const chStatus = useSelector(selectCHStatus)
  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    dispatch(fetchCompensationHistory())
  }, [])
  React.useEffect(() => {
    if (chStatus === 'fulfilled') {
      chData.forEach((item) => {
        if (
          item.dateOfChange.replaceAll('/', '') ===
          extractAfterLastSlash(history.location.pathname)
        ) {
          setData(item)
        }
      })
    }
  }, [chStatus])

  const goBack = () => {
    history.push(`/CompensationHistory`)
  }

  return (
    <div className={classes.container}>
      <div className={classes.navbar}>
        <Button
          className={classes.backButton}
          onClick={() => goBack()}
          aria-label="Back to Compensation History"
        >
          <ArrowBackIcon className={classes.backArrow} />
          <Typography variant="h2">Single Compensation</Typography>
        </Button>
      </div>
      <Grid container className={classes.body}>
        <Grid item container className={classes.box}>
          <Grid item className={classes.headerBar}>
            <Typography variant="h3">
              <b>Salary Change Summary</b>
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            className={classes.paper}
          >
            <Grid item container alignItems="center">
              {data && <SalaryChangeInfo item={data} />}
            </Grid>
            <Divider className={classes.divider} />
            <Grid item container className={classes.salaryChangeBox}>
              {data && (
                <SingleRow
                  v1=""
                  v2="Annual"
                  v3={data.compensationFrequency}
                  isHeader
                />
              )}
              {data && (
                <SingleRow
                  v1="Current Salary"
                  v2={data.currentSalary}
                  v3={data.currentCompPerFreq}
                />
              )}
              {data && (
                <SingleRow
                  v1="Change Amount"
                  v2={calculateDifference(data.newSalary, data.currentSalary)}
                  v3={calculateDifference(
                    data.newCompPerFreq,
                    data.currentCompPerFreq,
                  )}
                />
              )}
              {data && (
                <SingleRow
                  v1="New Salary"
                  v2={data.newSalary}
                  v3={data.newCompPerFreq}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default CompensationSingleHistory
