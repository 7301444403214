import React from 'react'
import {
  AppBar,
  IconButton,
  Grid,
  Toolbar,
  Typography as Text,
  Button,
  Chip,
} from '@material-ui/core'

import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import Phone from '@material-ui/icons/Phone'
import Email from '@material-ui/icons/Email'
import { Language } from '@material-ui/icons'
import ViewAppInfoMobileStyles from './ViewAppInfoMobileStyles'
import {
  createIcon,
  createImageCarousel,
  convertGSImgList,
  selectFavoritedAppAndUpdate,
} from '../../../helpers'
import {
  selectApps,
  selectIsGuest,
  selectAppCategories,
  selectMyAppsCallStatus,
  selectAppCategoriesWithApps,
  fetchMyApps,
} from '../../../appSlice'
import SetCarouselDimensions from '../../../AppComponents/AppCarouselCombined/AppCarouselDimensions'
import AppCarouselCombined from '../../../AppComponents/AppCarouselCombined/AppCarouselCombined'
import { addProfileLogEvent } from '../../../../dashboard/widgets/MyProfile/myProfileSlice'
import CheckIsMobile from '../../../../../../utils/isMobileHook'

/**
 * App Info Page For Mobile
 * Displays information about an app in mobile view
 */
export const ViewAppInfoMobile = () => {
  const classes = ViewAppInfoMobileStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const dimensions = SetCarouselDimensions()
  const isMobile = CheckIsMobile()

  const apps = useSelector(selectApps)
  const appCategories = useSelector(selectAppCategories)
  const myAppsSorted = useSelector(selectAppCategoriesWithApps)
  const isGuest = useSelector(selectIsGuest)
  const status = useSelector(selectMyAppsCallStatus)

  const [app, setApp] = React.useState({})
  const [categoryName, setCategoryName] = React.useState('')
  const [favoriteSelected, setFavoriteSelected] = React.useState(false)
  const [icon, setIcon] = React.useState(null)
  const [appCarousel, setAppCarousel] = React.useState(null)
  const [imageCarousel, setImageCarousel] = React.useState([])
  const [showImages, setShowImages] = React.useState(false)
  const [showAllTags, setShowAllTags] = React.useState(false)
  const [visibleTagCount, setVisibleTagCount] = React.useState(
    app?.tags?.length || 0,
  )
  const isPhoneNumber = (contact) => /^\+?[0-9\s-]+$/.test(contact)
  const isWebsite = (contact) =>
    /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\S*)?$/.test(contact)

  React.useEffect(() => {
    window.scrollTo(0, 0)
    if (status === 'idle' || status === 'pending') {
      dispatch(fetchMyApps())
    } else {
      const query = location.search
      const id = new URLSearchParams(query).get('appID')
      const selectedApp = apps.filter((a) => a.id === id)[0]
      const category = appCategories.filter(
        (c) => c.id === selectedApp.category.toString(),
      )[0]
      setCategoryName(category)
      createAppCarouselMobile(category)
      setApp(selectedApp)
      setIcon(createIcon(selectedApp.icon, '3x'))
      setFavoriteSelected(selectedApp.isFavorited)
      createCarouselImage(selectedApp)
    }
  }, [status, location])
  React.useEffect(() => {
    if (!app?.tags) return

    const charLimit = 80
    let runningCharSum = 0

    const count = app.tags.reduce((acc, tag) => {
      const tagWithSpacing = tag.length + 4
      if (runningCharSum + tagWithSpacing <= charLimit) {
        runningCharSum += tagWithSpacing
        return acc + 1
      }
      return acc
    }, 0)

    setVisibleTagCount(count)
  }, [app?.tags])

  const createCarouselImage = async (selectedApp) => {
    if (selectedApp.screenshotUrls) {
      const imageList = await convertGSImgList(selectedApp.screenshotUrls)
      createImageCarousel(classes, imageList, setImageCarousel)
      setShowImages(true)
    }
  }

  const createAppCarouselMobile = (selectedCategory) => {
    const cat = myAppsSorted.filter((c) => c.id === selectedCategory.id)[0]
    const carousel = (
      <AppCarouselCombined
        key={cat.name}
        appList={cat.apps}
        carouselType="carousel"
        category={cat.name}
        dimensions={dimensions}
        id={cat.id}
        isMobile={isMobile}
        isGuest={isGuest}
      />
    )
    setAppCarousel(carousel)
  }

  const handleFavorited = () => {
    selectFavoritedAppAndUpdate(dispatch, app.id)
  }

  return (
    <div className={classes.container}>
      <AppBar className={classes.appBar} position="fixed" elevation={2}>
        <Toolbar className={classes.container}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item sm={4}>
              <IconButton onClick={() => history.goBack()}>
                <ArrowBackIosIcon className={classes.mobileBackButtonIcon} />
              </IconButton>
            </Grid>
            <Grid item sm={4} className={classes.pageTitleContainer}>
              <Text className={classes.pageTitle}> {app.title} </Text>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div style={{ marginTop: 56, padding: 15 }}>
        <Grid container>
          <Grid
            xs={3}
            item
            className={classes.iconContainer}
            style={{ backgroundColor: app.color }}
          >
            {icon}
          </Grid>

          <Grid item xs={9} className={classes.headingContainer}>
            <Grid container direction="column" alignItems="flex-start">
              <Grid item>
                <Text component="span" className={classes.appNameText}>
                  {app.title}
                </Text>
              </Grid>

              <Grid item>
                <Text component="span" className={classes.categoryText}>
                  {categoryName.name}
                </Text>
              </Grid>

              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <IconButton onClick={() => handleFavorited()}>
                    {favoriteSelected ? (
                      <FavoriteIcon
                        className={classes.selectedFavoriteIconButton}
                      />
                    ) : (
                      <FavoriteBorderIcon
                        className={classes.iconButtonBorder}
                      />
                    )}
                  </IconButton>
                  <Button
                    className={classes.roundedButton}
                    onClick={() => {
                      if (process.env.REACT_APP_FIREBASE_ON === 'true') {
                        dispatch(
                          addProfileLogEvent({
                            module: 'Apps',
                            object: 'AppTile',
                            objectId: 'AppsInfo',
                            action: 'Click',
                            actionContext: 'ViewFragmentPage',
                            actionContextValue: app.id,
                            rcpid: localStorage.getItem('rutgersEduRCPID'),
                            timestamp: Date.now(),
                            deviceType: 'Web',
                          }),
                        )
                      }
                      window.open(app.url)
                    }}
                  >
                    Open
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {app.email && (
          <Button
            className={classes.roundedButton}
            startIcon={<Email />}
            onClick={() => {
              if (process.env.REACT_APP_FIREBASE_ON === 'true') {
                dispatch(
                  addProfileLogEvent({
                    module: 'Apps',
                    object: 'AppTile',
                    objectId: 'AppsInfo',
                    action: 'Click',
                    actionContext: 'MailToEmail',
                    actionContextValue: app.id,
                    rcpid: localStorage.getItem('rutgersEduRCPID'),
                    timestamp: Date.now(),
                    deviceType: 'Web',
                  }),
                )
              }
              window.open(`mailto:${app.email}`, '_blank')
            }}
          >
            {app.email}
          </Button>
        )}
        {app.contact && (
          <Button
            className={classes.roundedButton}
            startIcon={isPhoneNumber(app.contact) ? <Phone /> : <Language />}
            onClick={() => {
              if (process.env.REACT_APP_FIREBASE_ON === 'true') {
                dispatch(
                  addProfileLogEvent({
                    module: 'Apps',
                    object: 'AppTile',
                    objectId: 'AppsInfo',
                    action: 'Click',
                    actionContext: isPhoneNumber(app.contact)
                      ? 'CallPhoneNumber'
                      : 'OpenWebsite',
                    actionContextValue: app.id,
                    rcpid: localStorage.getItem('rutgersEduRCPID'),
                    timestamp: Date.now(),
                    deviceType: 'Web',
                  }),
                )
              }

              if (isPhoneNumber(app.contact)) {
                window.open(`tel:${app.contact}`, '_self')
              } else if (isWebsite(app.contact)) {
                const url = app.contact.startsWith('http')
                  ? app.contact
                  : `https://${app.contact}`
                window.open(url, '_blank')
              }
            }}
          >
            {app.contact}
          </Button>
        )}

        <Grid
          container
          direction="column"
          className={classes.descriptionContainer}
        >
          <Grid item style={{ marginLeft: '0px' }}>
            {app?.tags?.length > 0 ? (
              <div
                style={{
                  maxHeight: showAllTags ? '1000px' : '100px',
                }}
                className={classes.tagContainer}
              >
                {(showAllTags
                  ? app.tags
                  : app.tags.slice(0, visibleTagCount)
                ).map((tag) => (
                  <Chip
                    key={tag}
                    clickable={false}
                    label={tag}
                    size="small"
                    className={classes.pageToggleChip}
                  />
                ))}

                {app.tags.length > visibleTagCount && (
                  <div style={{ margin: 4 }}>
                    <Button
                      size="small"
                      onClick={() => setShowAllTags(!showAllTags)}
                      className={classes.viewMoreButton}
                    >
                      {showAllTags ? 'View Less' : 'View More'}
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <span>No tags available</span>
            )}
          </Grid>

          <Grid item>
            <Text className={classes.descriptionHeaderText}>{app.info}</Text>
          </Grid>

          <Grid item className={classes.infoTextContainer}>
            <Text className={classes.infoText}>{app.message}</Text>
          </Grid>
        </Grid>
      </div>

      {showImages ? imageCarousel : null}

      <div className={classes.appCarouselBody}>{appCarousel}</div>
    </div>
  )
}

export default ViewAppInfoMobile
