/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchCompensationHistory = createAsyncThunk(
  'fetchCompensationHistory',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')

    const res = await axios.get(
      process.env.REACT_APP_COMPENSATIONHISTORY_ENDPOINT,
      {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    )
    return res.data
  },
)

// This is the initial state object the reducers and extraReducers will change based on whatever we need to change it to. It is required when creating the createSlice object.
const initialState = {
  status: 'idle',
  imageStatus: 'idle',
  data: [],
}

const compensationHistorySlice = createSlice({
  name: 'myCompensationHistorySlice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCompensationHistory.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchCompensationHistory.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchCompensationHistory.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.user = action.payload.user
      state.data = action.payload.compensationHistory
    },
  },
})
// List of selector functions that can be used to access state inside of the CompensationHistory.js Component.
export const selectCHUser = (state) => state.compensationHistory.user
export const selectCHData = (state) => state.compensationHistory.data
export const selectCHStatus = (state) => state.compensationHistory.status

export default compensationHistorySlice.reducer
