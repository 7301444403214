import { makeStyles, createStyles } from '@material-ui/core/styles'

const WhatsNewSearchBarStyles = makeStyles((theme) =>
  createStyles({
    searchBarWrapper: {
      position: 'sticky',
      top: '75px',
      paddingBottom: 50,
      width: '365px',
      background: theme.palette.components.newsBackground,
      alignSelf: 'flex-start',
      marginRight: '20px',

      [theme.breakpoints.down('md')]: {
        width: '100%',
        top: '50px',
        zIndex: 20,
        paddingBottom: 20,
        paddingTop: 20,
        marginRight: '0px',
      },
    },
    sidePanel: {
      padding: '5px',
    },
    filterBar: {
      marginTop: '10px',
      height: '40px',
      display: 'flex',
      flexDirection: 'row',
    },
    filterBarHeader: {
      background: theme.color.rutgersRed,
      color: theme.color.white,
      width: '55px',
      padding: '10px',
    },
    filterBarContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    divider: {
      marginTop: '12px',
    },

    sidePanelText: {
      paddig: '5px',
      textAlign: 'center',
    },
  }),
)

export default WhatsNewSearchBarStyles
