/* eslint-disable import/no-unresolved */
import React from 'react'
import {
  Typography as Text,
  Grid,
  Box,
  Divider,
  IconButton,
  Button,
} from '@material-ui/core'
import dayjs from 'dayjs'
// import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import { useDispatch, useSelector } from 'react-redux'
import { ChevronLeft, ChevronRight, VisibilityOff } from '@material-ui/icons'
import Widget from '../Widget'
import myPaycheckStyles from './myPaycheckMockupStyles'
import {
  fetchMyPaycheckMockup,
  selectPaycheckNetPay,
  selectPaycheckPayDate,
  selectPaycheckPayPeriodStart,
  selectPaycheckPayPeriodEnd,
  selectPaycheckStatus,
  selectIconList,
  selectPaycheckItems,
} from './myPaycheckMockupSlice'
import Loading from '../helpers/Loading'
import { formatUSD } from '../../../../../utils/format'

import HideShowButton from '../../DashboardComponents/HideShowButton/HideShowButton'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'
import LinkList from '../../DashboardComponents/LinkList.js/LinkList'

const DATE_FORMAT = 'ddd, MMM D, YYYY'
const COMPACT_DATE_FORMAT = 'MMM D, YYYY'
function MyPaycheckMockUp({
  className,
  title = 'My Paycheck',
  widgetId = '900',
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
  employeeId = '10000198',
}) {
  const classes = myPaycheckStyles()
  const dispatch = useDispatch()
  const widgetCallState = useSelector(selectPaycheckStatus)
  console.log('widgetCallState', widgetCallState)
  const iconList = useSelector(selectIconList)
  const paycheckItems = useSelector(selectPaycheckItems)
  const paycheckNetPay = useSelector(selectPaycheckNetPay)
  const paycheckPayDate = useSelector(selectPaycheckPayDate)
  const paycheckPayPeriodStart = useSelector(selectPaycheckPayPeriodStart)
  const paycheckPayPeriodEnd = useSelector(selectPaycheckPayPeriodEnd)

  React.useEffect(() => {
    dispatch(fetchMyPaycheckMockup({ personNumber: '10000198' }))
  }, [])

  React.useEffect(() => {
    if (employeeId) {
      dispatch(fetchMyPaycheckMockup({ personNumber: employeeId }))
    }
  }, [employeeId])

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="employee" />
      <Widget.Content
        className={classes.content}
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {myPaycheckState(
          widgetCallState,
          widgetId,
          dispatch,
          paycheckNetPay,
          paycheckPayDate,
          paycheckPayPeriodStart,
          paycheckPayPeriodEnd,
          paycheckItems,
          iconList,
        )}
      </Widget.Content>
    </Widget>
  )
}

function myPaycheckState(
  widgetCallState,
  widgetId,
  dispatch,
  paycheckNetPay,
  paycheckPayDate,
  paycheckPayPeriodStart,
  paycheckPayPeriodEnd,
  paycheckItems,
  iconList,
) {
  const classes = myPaycheckStyles()
  // TODO: Handle Proper Error States
  console.log('widgetCallState ', widgetCallState)
  switch (widgetCallState) {
    case 'fulfilled':
      return (
        <Loaded
          paycheckNetPay={paycheckNetPay}
          paycheckPayDate={paycheckPayDate}
          paycheckPayPeriodStart={paycheckPayPeriodStart}
          paycheckPayPeriodEnd={paycheckPayPeriodEnd}
          paycheckItems={paycheckItems}
          widgetId={widgetId}
          dispatch={dispatch}
          iconList={iconList}
        />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className={classes.text}></Grid>
        </Grid>
      )
  }
}

function Loaded(props) {
  const classes = myPaycheckStyles()
  const { iconList, widgetId, dispatch, paycheckItems } = props
  console.log('paycheckItems ', paycheckItems)
  return (
    <div>
      <Widget.Spacer />
      <PaycheckDisplay
        paycheckNetPay={props.paycheckNetPay}
        paycheckItems={paycheckItems}
        dispatch={dispatch}
        widgetId={widgetId}
        classes={classes}
      />
      <Divider classes={{ root: classes.divider }} />
      <Widget.Spacer />
      <Grid container direction="column">
        <Grid item>
          <LinkList links={iconList} widgetId={widgetId} dispatch={dispatch} />
        </Grid>
      </Grid>
    </div>
  )
}

const PaycheckDisplay = (props) => {
  const [showHideShowButton, setShowHideShowButton] = React.useState(true)
  const { classes, paycheckItems } = props
  const additionalVoiceOver = `Your paycheck is ${
    props.paycheckNetPay ? formatUSD(parseFloat(props.paycheckNetPay)) : 'N/A'
  }`
  const [currentIndex, setCurrentIndex] = React.useState(0)

  const handleNext = () => {
    setCurrentIndex(currentIndex + 1)
  }

  const handlePrev = () => {
    setCurrentIndex(currentIndex - 1)
  }

  const handleShowHideShowButton = () => {
    setShowHideShowButton(!showHideShowButton)
  }

  const currentPaycheck = paycheckItems[currentIndex]

  if (showHideShowButton) {
    return (
      <Box>
        <Button
          style={{
            background: 'green',
            color: 'white',
            width: 320,
            height: 40,
            borderRadius: '20px',
          }}
          onClick={handleShowHideShowButton}
        >
          <VisibilityOff style={{ fontSize: 42, color: 'white' }} />
        </Button>
        <Widget.Spacer />
        <Box className={classes.paymentInfoContainer}>
          <Text variant="subtitle1" style={{ textAlign: 'left' }}>
            Payment Date:
            <span className={classes.paymentSpan}>
              {currentPaycheck?.PayslipViewDate
                ? ` ${dayjs(currentPaycheck.PayslipViewDate).format(
                    DATE_FORMAT,
                  )}`
                : ' N/A'}
            </span>
          </Text>
          <Text variant="subtitle1" style={{ textAlign: 'left' }}>
            Pay Period:
            {currentPaycheck?.PeriodStartDate ? (
              <span className={classes.paymentSpan}>
                {` ${dayjs(currentPaycheck.PeriodStartDate).format(
                  COMPACT_DATE_FORMAT,
                )} - ${dayjs(currentPaycheck.PeriodEndDate).format(
                  COMPACT_DATE_FORMAT,
                )}`}
              </span>
            ) : (
              <span className={classes.paymentSpan}> N/A</span>
            )}
          </Text>
        </Box>
      </Box>
    )
  }

  if (!paycheckItems || paycheckItems.length === 0) {
    // Render the single paycheck view if no paycheckItems
    return (
      <Box>
        <HideShowButton
          displayData={
            props.paycheckNetPay
              ? formatUSD(parseFloat(props.paycheckNetPay))
              : 'N/A'
          }
          color="green"
          widgetId={props.widgetId}
          dispatch={props.dispatch}
          moduleName="Paycheck"
          additionalVoiceOver={additionalVoiceOver}
          width={320}
          handleShowHideShowButton={handleShowHideShowButton}
        />

        <Widget.Spacer />
        <Box className={classes.paymentInfoContainer}>
          <Text variant="subtitle1" style={{ textAlign: 'left' }}>
            Payment Date:
            <span className={classes.paymentSpan}>
              {props?.paycheckPayDate
                ? ` ${dayjs(props.paycheckPayDate).format(DATE_FORMAT)}`
                : ' N/A'}
            </span>
          </Text>
          <Text variant="subtitle1" style={{ textAlign: 'left' }}>
            Pay Period:
            {props?.paycheckPayPeriodStart ? (
              <span className={classes.paymentSpan}>
                {` ${dayjs(props.paycheckPayPeriodStart).format(
                  COMPACT_DATE_FORMAT,
                )} - ${dayjs(props.paycheckPayPeriodEnd).format(
                  COMPACT_DATE_FORMAT,
                )}`}
              </span>
            ) : (
              <span className={classes.paymentSpan}> N/A</span>
            )}
          </Text>
        </Box>
      </Box>
    )
  }
  const isFirstPaycheck = currentIndex === 0
  const isLastPaycheck = currentIndex === paycheckItems.length - 1

  return (
    <div>
      <Box key={currentPaycheck?.PayslipViewDate}>
        <IconButton
          aria-label="Next"
          classes={{ root: classes.paymentColorsLeft }}
          onClick={handleNext}
          disabled={isLastPaycheck}
        >
          <ChevronLeft htmlColor="#fff" />
        </IconButton>
        <HideShowButton
          displayData={formatUSD(parseFloat(currentPaycheck.Amount))}
          color="green"
          widgetId={props.widgetId}
          dispatch={props.dispatch}
          moduleName="Paycheck"
          additionalVoiceOver={`Your paycheck is ${formatUSD(
            parseFloat(currentPaycheck.Amount),
          )}`}
          width={220}
          borderRadius={0}
          handleShowHideShowButton={handleShowHideShowButton}
        />
        <IconButton
          aria-label="Previous"
          classes={{ root: classes.paymentColorsRight }}
          onClick={handlePrev}
          disabled={isFirstPaycheck}
        >
          <ChevronRight htmlColor="#fff" />
        </IconButton>
        <Widget.Spacer />
        <Box className={classes.paymentInfoContainer}>
          <Text variant="subtitle1" style={{ textAlign: 'left' }}>
            Payment Date:
            <span className={classes.paymentSpan}>
              {currentPaycheck.PayslipViewDate
                ? ` ${dayjs(currentPaycheck.PayslipViewDate).format(
                    DATE_FORMAT,
                  )}`
                : ' N/A'}
            </span>
          </Text>

          <Text variant="subtitle1" style={{ textAlign: 'left' }}>
            Pay Period:
            {currentPaycheck?.PeriodStartDate ? (
              <span className={classes.paymentSpan}>
                {` ${dayjs(currentPaycheck.PeriodStartDate).format(
                  COMPACT_DATE_FORMAT,
                )} - ${dayjs(currentPaycheck.PeriodEndDate).format(
                  COMPACT_DATE_FORMAT,
                )}`}
              </span>
            ) : (
              <span className={classes.paymentSpan}> N/A</span>
            )}
          </Text>
        </Box>
      </Box>
    </div>
  )
}

export default MyPaycheckMockUp
