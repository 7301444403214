import { makeStyles } from '@material-ui/core/styles'

export const skipStyles = makeStyles(() => ({
  skipLink: {
    /* spacing styles */
    position: 'fixed',
    transform: 'translateX(-200%)',
    transition: 'transform 0.3s',
    /* button styles */
    padding: '1rem',
    marginTop: '0.6rem',
    fontWeight: 700,
    color: 'rgba(0, 0, 0, 0)',
    backgroundColor: '#ffffff',

    '&:focus': {
      position: 'fixed',
      transform: 'translateX(45%)',
      zIndex: 2147483647,
      color: 'rgb(0, 56, 199)',
    },
  },
}))

export default skipStyles
