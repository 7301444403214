import React from 'react'

import {
  APIProvider,
  Map,
  useMap,
  // eslint-disable-next-line
} from '@vis.gl/react-google-maps/dist/index.umd.js'

const MapPanelTwo = () => (
  <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
    <MyMap />
  </APIProvider>
)

const MyMap = () => {
  const map = useMap()
  function setStyle(/* FeatureStyleFunctionOptions */ params) {
    const datasetFeature = params.feature
    const furColors =
      datasetFeature.datasetAttributes.CombinationofPrimaryandHighlightColor

    switch (furColors) {
      case 'Black+':
        return { fillColor: 'black', pointRadius: 8 }
      case 'Cinnamon+':
        return { fillColor: '#8b0000', pointRadius: 8 }
      case 'Cinnamon+Gray':
        return {
          fillColor: '#8b0000',
          strokeColor: 'gray',
          pointRadius: 6,
        }
      case 'Cinnamon+White':
        return {
          fillColor: '#8b0000',
          strokeColor: 'white',
          pointRadius: 6,
        }
      case 'Gray+':
        return { fillColor: 'gray', pointRadius: 8 }
      case 'Gray+Cinnamon':
        return {
          fillColor: 'gray',
          strokeColor: '#8b0000',
          pointRadius: 6,
        }
      case 'Gray+Cinnamon, White':
        return {
          fillColor: 'silver',
          strokeColor: '#8b0000',
          pointRadius: 6,
        }
      case 'Gray+White':
        return {
          fillColor: 'gray',
          strokeColor: 'white',
          pointRadius: 6,
        }
      default:
        return {
          strokeColor: '#cc0033',
          strokeOpacity: 1.0,
          strokeWeight: 3.0,
          fillColor: '#cc0033',
          fillOpacity: 0.5,
        }
    }
  }
  console.log('map ', map)

  if (map) {
    const mapCapabilities = map.getMapCapabilities()
    console.log('data driven styles ', mapCapabilities)
    const currentDatasetLayer = map.getDatasetFeatureLayer(
      'd744edd6-00be-46d3-bf7b-3efc87c9bf40',
    )
    console.log('currentDatasetLayer ', currentDatasetLayer)
    if (currentDatasetLayer) {
      currentDatasetLayer.style = (params) => {
        const featurePosition = params.feature.datasetAttributes
        console.log('params.feature ', featurePosition)
        return setStyle(params)
      }
    }
  }
  return (
    <Map
      mapId="2b3f87ff16688c1e"
      style={{ width: '100vw', height: '100vh' }}
      defaultCenter={{ lat: 40.51868, lng: -74.46147, zoom: 18 }}
      defaultZoom={14}
      gestureHandling="greedy"
      disableDefaultUI
    />
  )
}

export default MapPanelTwo
