import React from 'react'
import { Typography, Grid, Button, TextField, Paper } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { PropTypes } from 'prop-types'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'
import { camelCaseToHuman, numberToMoney } from '../../../../../utils/utils'
import { CompensationHistoryStyles } from './CompensationHistoryStyles'

const blankUser = {
  one: false,
  two: false,
  three: false,
  four: false,
  five: false,
}

const EmployeeInfo = ({ user }) => {
  // takes user object and creates 5 key value rows
  // switch case is necessary to remove fName lName and picture
  const final = []

  Object.keys(user).forEach((key) => {
    // collect KeyValueRow into an array
    switch (true) {
      case key === 'firstName':
      case key === 'lastName':
      case key === 'profilePhoto':
        break
      case key === 'jobTitle':
      case key === 'employeeID':
      case key === 'department':
      case key === 'payrollStatus':
      case key === 'flsaStatus':
        final.push(
          <KeyValueRow
            key={`${key} ${user[key]}`}
            left={key}
            right={user[key]}
          />,
        )
        break
      default:
        final.push(
          <KeyValueRow
            key={`${key} ${user[key]}`}
            left={key}
            right={user[key]}
          />,
        )
        break
    }
  })
  // render all items in array
  return <>{final.map((item) => item)}</>
}

EmployeeInfo.propTypes = {
  user: PropTypes.object.isRequired,
}

const KeyValueRow = ({ left, right }) => {
  // takes two strings, key on left, value on right
  // renders them into one row
  const classes = CompensationHistoryStyles()
  const isSkeleton = right === false

  // take camelCase values and make them human readable
  const formattedKey =
    left === 'employeeID' ? 'Employee ID' : camelCaseToHuman(left)

  const skeletonHeight = 17
  const randomWidth = 100 + formattedKey.length * 4
  return (
    <>
      <Grid item xs={6} className={classes.keyGrid}>
        {isSkeleton ? (
          <Skeleton
            variant="rect"
            width={randomWidth}
            height={skeletonHeight}
            style={{ margin: `0px 0px` }}
          />
        ) : (
          <Typography variant="subtitle1" className={classes.key}>
            {formattedKey}
          </Typography>
        )}
      </Grid>
      <Grid item xs={6}>
        {isSkeleton ? (
          <Skeleton
            variant="rect"
            width={randomWidth}
            height={skeletonHeight}
            style={{ margin: `4px 0px` }}
          />
        ) : (
          <Typography variant="body1" className={classes.bodyText}>
            {right}
          </Typography>
        )}
      </Grid>
    </>
  )
}

KeyValueRow.propTypes = {
  left: PropTypes.string.isRequired,
  right: PropTypes.any.isRequired,
}

const TableHeaderCell = ({ isLastBox, string1, string2 }) => {
  // isLastBox changes align to flex-end
  // string1 is default
  // string 2 is there if you need to break string onto two lines
  const classes = CompensationHistoryStyles()
  return (
    <Grid item xs={3} className={isLastBox && classes.lastBox}>
      <Typography variant="subtitle1" className={classes.tableHeaderText}>
        {string2 ? (
          <>
            {string1}
            <br />
            {string2}
          </>
        ) : (
          string1
        )}
      </Typography>
    </Grid>
  )
}

TableHeaderCell.propTypes = {
  isLastBox: PropTypes.bool,
  string1: PropTypes.string.isRequired,
  string2: PropTypes.string,
}

const CompHistoryRow = ({ item, index, handleSalaryClick }) => {
  // takes single salary item and renders it as one row
  // handles date styling, percent icon, and money
  // handleSalaryClick opens item onto new page and changes slug
  const classes = CompensationHistoryStyles()

  return (
    <Grid
      item
      container
      className={
        index % 2
          ? `${classes.evenRow} ${classes.row}`
          : `${classes.oddRow} ${classes.row}`
      }
      key={`${item.dateOfChange} ${index}`}
      component={Button}
      onClick={() => handleSalaryClick(item.dateOfChange)}
      aria-label={`Change on ${item.dateOfChange}`}
    >
      <Grid item xs={3} className={classes.frontBox}>
        <Typography
          variant="body1"
          className={`${classes.date} ${classes.bodyText}`}
        >
          {item.dateOfChange}
        </Typography>
      </Grid>
      <Grid item xs={3} className={classes.frontBox}>
        <Typography variant="body1" className={classes.bodyText}>
          {item.changePercent.toString().includes('-') ? (
            <IconPicker iconName="fa-solid fa-arrow-trend-down" color="red" />
          ) : (
            <IconPicker iconName="fa-solid fa-arrow-trend-up" color="green" />
          )}{' '}
          {item.changePercent}%
        </Typography>
      </Grid>
      <Grid item xs={3} className={classes.frontBox}>
        <Typography variant="body1" className={classes.bodyText}>
          ${numberToMoney(item.newSalary)}
        </Typography>
      </Grid>
      <Grid item xs={3} className={classes.lastBox}>
        <Typography variant="body1" className={classes.bodyText}>
          ${numberToMoney(item.newCompPerFreq)}
        </Typography>
      </Grid>
    </Grid>
  )
}

CompHistoryRow.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleSalaryClick: PropTypes.func.isRequired,
}

const DateFilterContent = ({
  start,
  end,
  tempStart,
  tempEnd,
  handleStartChange,
  handleEndChange,
  isSmall,
}) => {
  // content to pass to modal when date filter is opened
  // all dates are required to handle button disabled logic
  const classes = CompensationHistoryStyles()

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      className={classes.dateFilterContent}
    >
      <Grid item className={classes.dateFilterGrid}>
        <Paper className={classes.dateFilterPaper}>
          <Typography variant="h2">Start Date</Typography>
          <Typography variant="body1">Format: YYYY - MM - DD</Typography>
          <TextField
            id="date"
            label="Start Date"
            type="date"
            variant="outlined"
            value={tempStart !== '' ? tempStart : start}
            className={classes.dateTextField}
            onChange={handleStartChange}
          />
        </Paper>
      </Grid>
      <Grid item className={classes.dateFilterGrid}>
        <Paper className={classes.dateFilterPaper}>
          <Typography variant={isSmall ? 'h3' : 'h2'}>End Date</Typography>
          <Typography variant="body1">Format: YYYY - MM - DD</Typography>
          <TextField
            id="date"
            label="End Date"
            type="date"
            variant="outlined"
            value={tempEnd !== '' ? tempEnd : end}
            className={classes.dateTextField}
            onChange={handleEndChange}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

DateFilterContent.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  tempStart: PropTypes.string.isRequired,
  tempEnd: PropTypes.string.isRequired,
  handleStartChange: PropTypes.func.isRequired,
  handleEndChange: PropTypes.func.isRequired,
}

export {
  EmployeeInfo,
  TableHeaderCell,
  CompHistoryRow,
  DateFilterContent,
  blankUser,
}
