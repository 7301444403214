import { makeStyles, createStyles } from '@material-ui/core/styles'

const WhatsItemListStyles = makeStyles((theme) =>
  createStyles({
    container: {
      paddingTop: '70px',
      paddingLeft: '60px',
      paddingBottom: '50px',
      display: 'flex',

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: '0px',
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: '50px',
      },
      background: theme.palette.components.newsBackground,
    },
    itemList: {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  }),
)

export default WhatsItemListStyles
