import React from 'react'

import { Box } from '@material-ui/core'
import whatsNewPageStyles from './WhatsNewPageStyle'
import WhatsNewItemList from './components/itemList/WhatsNewItemList'
const WhatsNewPage = () => {
  const classes = whatsNewPageStyles()
  return (
    <Box className={classes.root}>
      <WhatsNewItemList />
    </Box>
  )
}

export default WhatsNewPage
